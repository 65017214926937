import React, { useState, useEffect, useRef } from 'react';
import './saved-property-list-item.scss';
import Property from '../../../models/property';
import { roundString } from '../../../utilities';
import { useNavigate } from "react-router-dom";
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

import {
  Button,
  IconButton,
  TextInput,
  //@ts-ignore
} from '@carbon/react';

import {
  Document,
  Enterprise,
  FavoriteFilled,
  TrashCan,
  User,
  Edit,
  Checkmark,
  Close,
  // @ts-ignore
} from '@carbon/icons-react';

interface iProps {
  property: Property;
  removeProperty: (propertyId: number) => void;
  renameProperty: Function | null;
  hasNote: boolean;
}


export default function SavedPropertyListItem(props: iProps) {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [newName, setNewName] = useState<any>(null);
  const renameInput = useRef<HTMLInputElement>(null);
  const hasManagerInfo = props.property.propertyManagerContact || props.property.propertyManagerName;
  const hasOwnerInfo = props.property.ownerContact || props.property.ownerName;
  const hasLeasingCompanyInfo = props.property.leasingCompanyContact || props.property.leasingCompanyName;

  useEffect(() => {

    if (props.renameProperty) {

      const faveProperties = new FeatureLayer({ url: "https://services1.arcgis.com/dqIGQgErV3nGqtSd/arcgis/rest/services/SmartReach_User_Tables/FeatureServer/4" });
      faveProperties!.queryFeatures({
        where: `property_id = ${props.property.id}`,
        outFields: ["*"],
      }).then((result) => {
        if (result.features.length > 0) {
          const featureToUpdate = result.features[0];
          const currentValue = featureToUpdate.attributes.property_title;
          setNewName(
            currentValue?.trim() || props.property.name?.trim() || props.property.address
          );
        } else {
          console.error("Feature not found");
        }
      });
    }

  }, [])

  const remove = (evt: any) => {
    evt.stopPropagation();
    if (props.removeProperty)
      props.removeProperty(props.property.id);
      setNewName(null)

  }

  const goToProperty = () => {
    navigate("/properties", { replace: false, state: { property: props.property } });
  }

  const rename = (evt: any) => {
    evt.stopPropagation();
    console.log('rename');
    setRenaming(true);
  }

  useEffect(() => {
    if (renameInput.current) {
      renameInput.current.focus();
    }
  }, [renaming])

  const submitNewName = (evt: any) => {
    evt.stopPropagation();
    if (newName) {
      setRenaming(false);
      if (props.renameProperty) {
        props.renameProperty(props.property.id, newName);
      }
    }
  }

  const cancelRename = (evt: any) => {
    evt.stopPropagation();
    setRenaming(false);
  }

  const iconSize = 16;
  return (
    <div className='saved-property-list-item'
      onClick={goToProperty}
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false) }} >
      <div className="row">
        <div style={{ flex: 1.2 }}>
          {props.renameProperty &&
            <div className="section">
              <div className="sub-section mb">
                {!renaming &&
                  <span className="text-primary mb">
                    {newName?.trim() || props.property.name?.trim() || props.property.address}
                  </span>
                }

                {renaming &&
                  <div className="rename-container">
                    <TextInput
                      className='rename-input'
                      ref={renameInput}
                      id={`rename-search-${props.property.id}-input`}
                      type='text'
                      hideLabel={true}
                      size='sm'
                      labelText={`Rename Saved Property`}
                      value={newName}
                      onChange={(evt: any) => {
                        if (evt.target.value.length <= 50) { // Example character limit
                          setNewName(evt.target.value);
                        }
                      }}
                      onClick={(evt: any) => { evt.stopPropagation() }}
                      onKeyDown={(evt: any) => { if (evt.key === 'Enter') { submitNewName(evt) } }}
                      maxLength={50} // Set the maximum number of characters
                      placeholder='Enter new name...'
                    />
                    <IconButton
                      onClick={submitNewName}
                      label="Submit"
                      kind="primary"
                      size="sm"
                    >
                      <Checkmark />
                    </IconButton>
                    <IconButton
                      onClick={cancelRename}
                      label="Cancel"
                      kind="secondary"
                      size="sm"
                    >
                      <Close />
                    </IconButton>
                  </div>
                }
                {!renaming &&
                  <span className='favorite-span'>
                    <FavoriteFilled size={iconSize} className="is-favorite" />
                    <Document size={iconSize} className={props.hasNote ? "" : "disabled-icon"} />
                  </span>
                }
              </div>

              {/*<div className='sub-section text-secondary'>
            {getDescription()}
          </div>*/}
            </div>
          }
          {!props.renameProperty &&
            <div className="sub-section">
              <span className="text-primary" >
                {props.property.name ? props.property.name : props.property.address}
              </span>
              <span className='favorite-span'>
                <FavoriteFilled size={iconSize} className="is-favorite" />
                <Document size={iconSize} className={props.hasNote ? "" : "disabled-icon"} />
              </span>
            </div>
          }
          {/*<div className="sub-section mb">
            <span className="text-primary">
              {props.property.name ? props.property.name : props.property.address}
            </span>
            <FavoriteFilled size={iconSize} className="is-favorite" />
            <Document size={iconSize} className={props.hasNote ? "" : "disabled-icon"} />
          </div>*/}

          <div className='sub-section text-secondary'>
            {props.property.address}, {props.property.city}, {props.property.state} {props.property.zip}
          </div>
        </div>

        <div style={{ flex: 1 }} className='sub-section text-tertiary'>
          <span>
            {props.property.type}
          </span>
          <span>
            {roundString(props.property.acres, 1)} acres
          </span>
        </div>

        {
          !hover &&

          <div className="sub-section rh-item">
            <User size={iconSize} className={hasManagerInfo ? "" : "disabled-icon"} />
            <Enterprise size={iconSize} className={hasOwnerInfo || hasLeasingCompanyInfo ? "" : "disabled-icon"} />
            <span>
              {props.property.rank}%
            </span>
          </div>
        }
        {
          hover &&
          <div className='rh-item'>
                        {
              hover && !renaming && props.renameProperty &&
              <div className='rename-button'>
                <Button
                  onClick={rename}
                  kind="ghost"
                  size="sm">
                  Rename&nbsp;
                  <Edit className="rename-icon" />
                </Button>
                {/*<Button
              onClick={remove}
              kind="danger--ghost"
              size="sm">
              Delete&nbsp;
              <TrashCan />
            </Button>*/}
              </div>
            }
            <Button
              onClick={remove}
              kind="danger--ghost"
              size="sm">
              Remove&nbsp;
              <TrashCan />
            </Button>
          </div>
        }
      </div>
    </div>
  );
}