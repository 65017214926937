import React, { useEffect, useRef, useState, lazy, Suspense, MutableRefObject, ReactNode } from 'react';
import "./app.scss";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import config from './config';
import AuthGuard from './components/auth-guard/AuthGuard';
import NavBar from './components/navbar/NavBar';
import Home from './components/home/Home';
import Start from './components/start/Start';
import Prospecting from './components/prospecting/Prospecting';
import CRMupload from './components/CRMupload/CRMupload';
import SavedProperties from './components/saved-properties/SavedProperties';
import SavedSearches from './components/saved-searches/SavedSearches';
import AuthService from './services/auth';
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import * as msal from '@azure/msal-browser';

import Property from "./models/property";


import {
  Content,
  Theme
  //@ts-ignore
} from '@carbon/react';
import PropertyDetail from './components/prospecting/property-list/property-details/PropertyDetails';


const IFrame = lazy(() => import('./components/iframe/IFrame'));

/*interface iProps {
  property: Property;
}*/

export default function App(/*props: iProps*/) {
  const authService = useRef(AuthService.getInstance())

  useEffect(() => {
    if (!AuthService.initialized) {
      authService.current.initializeIdentityManager(config.oAuthInfo.appId, config.oAuthInfo.portalUrl);
    }
  }, []);


  const [statusChecked, setStatusChecked] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [user, setUser] = useState<__esri.PortalUser | null>(null);
  const notesTable = useRef<__esri.FeatureLayer>();
  const savedPropertiesTable = useRef<__esri.FeatureLayer>();
  const savedSearchesTable = useRef<__esri.FeatureLayer>();
  const [savedProperties, setSavedProperties] = useState<Set<number>>();
  const [notedProperties, setNotedProperties] = useState<Set<number>>(new Set());
  const [signedIn, setSignedIn] = useState(false);
  const [Init, setInit] = useState(false);
  const authInProcess = useRef(false);
  const hasTriggered: any = useRef(false);

  const microsoftName = sessionStorage.getItem('MicrosoftName')?.replace(/^"|"$/g, '');

  useEffect(() => {
    if (!statusChecked) {
      if (authService.current.hasValidCredential()) {
        setIsSignedIn(true);
        setStatusChecked(true);
        authService.current.getPortalUser().then((user) => { setUser(user) });
      } else {
        authService.current.checkCurrentStatus()
          .then(() => {
            setIsSignedIn(true);
            setStatusChecked(true);
            authService.current.getPortalUser().then((user) => { setUser(user) });
          })
          .catch(() => {
            setIsSignedIn(false);
            setStatusChecked(true);
          })
      }
    }
  }, [statusChecked])

  const login = () => {
    return authService.current.signIn()
      .then(() => {
        setIsSignedIn(true);
        return authService.current.getPortalUser()
          .then((user) => {
            setUser(user);
          });
      })
      .catch((error) => {
        // Check if the error message contains CORS-related text
        if (error.message.includes('Failed to fetch')) {
          // Handle CORS error
          console.error('CORS error:', error);
          // Display an error message to the user
          alert('ArcGIS Servers are experiencing issues. Please try again later.');
        }
        // Return a rejected promise to propagate the error further if needed
        return Promise.reject(error);
      });
  };


  const logout = () => {
    return authService.current.signOut().then(() => {
      setIsSignedIn(false);
      setUser(null);
    });
  }

  /*const [lastMovementDate, setLastMovementDate] = useState(new Date().toDateString());

  useEffect(() => {
    const handleMouseMove = (event: { clientX: any; clientY: any; }) => {
      const currentDate = new Date().toDateString();

      console.log(currentDate, lastMovementDate)

      if (currentDate !== lastMovementDate) {
        setLastMovementDate(currentDate);
        console.log(`Mouse moved: X=${event.clientX}, Y=${event.clientY} on ${currentDate}`);

        // You can perform other actions here, like sending data to your server
      }
    }; // Adjust the interval as needed

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [lastMovementDate]);*/

  useEffect(() => {
    if (user?.username) {
      notesTable.current = new FeatureLayer({ url: config.userTables.notes });
      notesTable.current.queryFeatures({
        where: `user_id = '${user.username}'`,
        outFields: ['property_id'],
        returnDistinctValues: true,
        returnGeometry: false,
      }).then(response => {
        const propertyIds = response.features.map((feature) => feature.attributes['property_id']);
        setNotedProperties(new Set(propertyIds));
      });

      savedPropertiesTable.current = new FeatureLayer({ url: config.userTables.favoriteProperties });
      savedPropertiesTable.current.queryFeatures({
        where: `user_id = '${user.username}'`,
        returnGeometry: false,
        returnDistinctValues: true,
        outFields: ['property_id']
      }).then(response => {
        const favProperties = response.features.map((feature) => feature.attributes['property_id']);
        setSavedProperties(new Set(favProperties));
      });

      savedSearchesTable.current = new FeatureLayer({ url: config.userTables.savedSessions });
    }
  }, [user?.username])

  const addNoteToProperty = (note: string, propertyId: number) => {
    if (note && notesTable.current) {
      const promise = notesTable.current.applyEdits({
        addFeatures: [
          new Graphic({
            attributes: {
              user_id: user?.username,
              property_id: propertyId,
              note_str: note,
              user_full_name: user?.fullName
            }
          })
        ]
      })

      promise.catch((reason) => { console.error(reason) });

      const noteProps = new Set(notedProperties);
      noteProps.add(propertyId);
      setNotedProperties(noteProps);

      return promise;
    } else {
      return null;
    }
  }

  const deleteNote = (objectId: number) => {
    notesTable.current?.applyEdits({
      deleteFeatures: [{ objectId }]
    }).then(() => {
      if (!user?.username) return;
      notesTable.current?.queryFeatures({
        where: `user_id = '${user.username}'`,
        outFields: ['property_id'],
        returnDistinctValues: true,
        returnGeometry: false,
      }).then(response => {
        const propertyIds = response.features.map((feature) => feature.attributes['property_id']);
        setNotedProperties(new Set(propertyIds));
      });
    })
  }

  const updateNote = (objectId: number, newNoteText: string) => {
    if (objectId && notesTable.current) {
      const updateFeatures = [
        new Graphic({
          attributes: {
            OBJECTID: objectId,
            note_str: newNoteText,
            user_full_name: user?.fullName
          }
        })
      ]
      return notesTable.current.applyEdits({ updateFeatures });
    } else {
      return null
    }
  }

  const favoriteProperty = (propertyId: number) => {
    const favProps = new Set(savedProperties);
    favProps.add(propertyId);
    setSavedProperties(favProps);
    savedPropertiesTable.current?.queryFeatureCount({
      where: `user_id = '${user?.username}' AND property_id = '${propertyId}' AND ms_name = '${microsoftName}'`
    }).then(count => {
      if (count === 0) {
        savedPropertiesTable.current?.applyEdits({
          addFeatures: [new Graphic({
            attributes: {
              user_id: user?.username,
              property_id: propertyId,
              ms_name: microsoftName
            }
          })]
        })
      }
    });
  }

  const unfavoriteProperty = (propertyId: number) => {
    const favProps = new Set(savedProperties);
    favProps.delete(propertyId);
    setSavedProperties(favProps);
    savedPropertiesTable.current?.queryFeatures({
      where: `user_id = '${user?.username}' AND property_id = '${propertyId}' AND ms_name = '${microsoftName}'`,
      outFields: ['OBJECTID']
    }).then(response => {
      if (response.features.length > 0) {
        savedPropertiesTable.current?.applyEdits({
          deleteFeatures: response.features
        })
      }
    });
  }

  const removeSavedSearch = (objectId: number) => {
    savedSearchesTable.current?.applyEdits({
      deleteFeatures: [{ objectId }]
    })
  }

  const renameSavedSearch = (objectId: number, newName: string) => {
    savedSearchesTable.current?.queryFeatures({
      where: `OBJECTID = ${objectId}`,
      returnGeometry: false,
      outFields: ['session_title', 'OBJECTID']
    }).then(featureSet => {
      const updateFeatures = featureSet.features.map(feature => {
        feature.attributes.session_title = newName;
        return feature;
      })
      savedSearchesTable.current?.applyEdits({ updateFeatures });
    });
  }

  // MSAL AUTH

  let REDIRECT = ''

  const urlPatterns = [
    { pattern: /^http:\/\/localhost:3000\/?$/, redirect: 'http://localhost:3000/' },
    { pattern: /dev/, redirect: 'https://dev.smartreach.app/' },
    { pattern: /smartreach\.app$/, redirect: 'https://smartreach.app/' },
  ];

  for (const { pattern, redirect } of urlPatterns) {
    if (pattern.test(window.location.href)) {
      REDIRECT = redirect;
      break;
    }
  }

  useEffect(() => {

    let usernameInsessionStorage = sessionStorage.getItem('username');

    if (!usernameInsessionStorage && !authInProcess.current) {
      initializeMSAL()
    }

  }, []);

  let visibilityChangeListener = () => {
    if (document.visibilityState === 'visible' && !hasTriggered.current) {
      const currentTime = new Date().getTime();
      const lastHiddenTimestamp = parseInt(sessionStorage.getItem('lastHiddenTimestamp') || '0');
      if (lastHiddenTimestamp === 0) return; // skip if this is the first visit
      const timeElapsed = currentTime - lastHiddenTimestamp;
      if (timeElapsed >= 3600000) {
        console.log("eventlistener");
        signIn();
        hasTriggered.current = true;
      }
    } else if (document.visibilityState === 'hidden') {
      sessionStorage.setItem('lastHiddenTimestamp', new Date().getTime().toString());
      hasTriggered.current = false;
    }
  };

  useEffect(() => {
    visibilityChangeListener()
    window.addEventListener('visibilitychange', visibilityChangeListener);
    return () => {
      // Remove the event listener
      window.removeEventListener('visibilitychange', visibilityChangeListener);
    };
  }, []);

  // When user logs out
  /*logoutButton.addEventListener('click', () => {
    // Remove the event listener
    window.removeEventListener('visibilitychange', visibilityChangeListener);
    // Log out the user
    clientApp.logout();
    hasTriggered.current = false; // Reset the flag
    sessionStorage.removeItem('lastHiddenTimestamp'); // Remove the timestamp
  });*/

  const loginRequest = {
    scopes: ["User.Read"]
  };

  const msalConfig = {
    auth: {
      clientId: '304174b1-b634-4b30-8bde-c97e87363624',
      authority: 'https://login.microsoftonline.com/600164ec-a4e1-4d57-9072-dc91a9f32d3c',
      redirectUri: REDIRECT,
      //redirectUri: 'http://localhost:5173/',
      storeAuthStateInCookie: true
    },
  };

  const myMSALObj = new msal.PublicClientApplication(msalConfig);

  /**
   * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
   */
  const tokenRequest = {
    scopes: ["User.Read", "Mail.Read"],
    forceRefresh: true // Set this to "true" to skip a cached token and go to the server to get a new token
  };

  async function initializeMSAL() {
    await new Promise(resolve => setTimeout(resolve, 500));
    authInProcess.current = true;
    console.log("initializing");

    try {
      await myMSALObj.initialize();
      console.log("MSAL initialized");
      signIn();
    } catch (error) {
      console.error("Error initializing MSAL:", error);
      // Optionally add some delay before retrying
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      window.location.reload();
    }
  }


  //let username = ''


  function selectAccount() {

    /**
     * See here for more info on account retrieval: 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */

    const currentAccounts = myMSALObj.getAllAccounts();
    if (currentAccounts.length === 0) {
      return;
    } else if (currentAccounts.length > 1) {
      // Add choose account code here
      console.warn("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
      //setUsername(currentAccounts[0].username);
      sessionStorage.setItem('username', JSON.stringify(currentAccounts[0].username));
      sessionStorage.setItem('MicrosoftName', JSON.stringify(currentAccounts[0].name));
      //showWelcomeMessage(username);
      setSignedIn(true)
      return true
    }
  }

  function handleResponse(response: any) {

    /**
     * To see the full list of response object properties, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
     */

    if (response !== null) {
      //setUsername(response.account.username);
      console.log(response.account)
      sessionStorage.setItem('username', JSON.stringify(response.account.username));
      sessionStorage.setItem('MicrosoftName', JSON.stringify(response.account.name));
      //showWelcomeMessage(username);
      setSignedIn(true)
      return true
    } else {
      selectAccount();
    }
  }


  function signIn() {
    console.log("signing in")
    authInProcess.current = true

    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */

    myMSALObj.loginPopup(loginRequest)
      .then(handleResponse)
      .catch((error: any) => {
        console.error(error);
        //window.location.reload()
        initializeMSAL()
      })
      .finally(() => { authInProcess.current = false; sessionStorage.setItem('initialSignIn', "YES") })
  }

  const usernameInsessionStorage = sessionStorage.getItem('username');

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {(signedIn || usernameInsessionStorage) &&
        <Theme className="content" theme="white">
          <Theme theme="g100">
            <NavBar userIsSignedIn={isSignedIn} user={user} login={login} logout={logout} />
          </Theme>
          <Content>
            <Routes>
              <Route path="/" element={<Home isSignedIn={isSignedIn} login={login} />} />
              <Route path="start" element={
                <AuthGuard statusChecked={statusChecked} isSignedIn={isSignedIn}>
                  <Start
                    user={user}
                    propertyLayerUrl={config.propertyLayer}
                    savedSessionsTableUrl={config.userTables.savedSessions}
                    favoritePropertiesTableUrl={config.userTables.favoriteProperties}
                    notedProperties={notedProperties}
                    savedProperties={savedProperties}
                    removeSavedSearch={removeSavedSearch}
                    renameSavedSearch={renameSavedSearch}
                  />
                </AuthGuard>
              } />
              <Route path="start/saved-searches" element={
                <AuthGuard statusChecked={statusChecked} isSignedIn={isSignedIn}>
                  <SavedSearches
                    user={user}
                    savedSearchesTableUrl={config.userTables.savedSessions}
                    removeSavedSearch={removeSavedSearch}
                    renameSavedSearch={renameSavedSearch}
                  />
                </AuthGuard>
              } />
              <Route path="start/saved-properties" element={
                <AuthGuard statusChecked={statusChecked} isSignedIn={isSignedIn}>
                  <SavedProperties
                    user={user}
                    propertyLayerUrl={config.propertyLayer}
                    favoritePropertiesTableUrl={config.userTables.favoriteProperties}
                    unfavoriteProperty={unfavoriteProperty}
                    notedProperties={notedProperties}
                  />
                </AuthGuard>
              } />
              <Route path="properties" element={
                <AuthGuard statusChecked={statusChecked} isSignedIn={isSignedIn}>
                  <Prospecting
                    user={user}
                    //property={props.property}
                    userTables={config.userTables}
                    notesTable={notesTable}
                    notedProperties={notedProperties}
                    addNoteToProperty={addNoteToProperty}
                    deleteNote={deleteNote}
                    updateNote={updateNote}
                    savedProperties={savedProperties}
                    favoriteProperty={favoriteProperty}
                    unfavoriteProperty={unfavoriteProperty}
                    drawSymbol={config.drawSymbol}
                  />
                </AuthGuard>
              } />
              <Route path="new-sales" element={
                <AuthGuard statusChecked={statusChecked} isSignedIn={isSignedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <IFrame />
                  </Suspense>
                </AuthGuard>
              } />
              <Route path="/contacts" element={
                <AuthGuard statusChecked={statusChecked} isSignedIn={isSignedIn}>
                  <CRMupload
                    user={user}
                    userTables={config.userTables}
                    propertyLayerUrl={config.propertyLayer}
                    savedSessionsTableUrl={config.userTables.savedSessions}
                    favoritePropertiesTableUrl={config.userTables.favoriteProperties}
                    notedProperties={notedProperties}
                    savedProperties={savedProperties}
                    removeSavedSearch={removeSavedSearch}
                    renameSavedSearch={renameSavedSearch}
                  />
                </AuthGuard>
              } />
            </Routes>
          </Content>
        </Theme>
      }
    </BrowserRouter>
  );
}