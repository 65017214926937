import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { render } from "react-dom";
import './property-list.scss';

import { useLocation } from 'react-router-dom';
import Property from '../../../models/property';
import PropertyListItem from "../../shared/property-list-item/PropertyListItem";
import { formatPhoneNumber, roundString } from "../../../utilities";
import PropertyDetails from "./property-details/PropertyDetails";
import { eachAlways } from "@arcgis/core/core/promiseUtils";
import { intersect } from "@arcgis/core/geometry/geometryEngine";
//import { whenFalse, whenFalseOnce } from '@arcgis/core/core/watchUtils';
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils"
import Point from "@arcgis/core/geometry/Point";
import { saveAs } from 'file-saver';

//UPDATES FROM DALTON: on click white circle
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import Graphic from "@arcgis/core/Graphic";
//END UPDATES FROM DALTON

import {
  Checkbox,
  Dropdown,
  //UPDATES FROM DALTON
  ToastNotification,
  //END UPDATES FROM DALTON
  //UPDATES FROM NATALIE
  Button,
  //IconButton, removed for loading more properties
  //END UPDATES FROM NATALIE    
  IconButton,
  InlineLoading,
  Loading,
  Modal,
  //@ts-ignore
  OverflowMenu,
  OverflowMenuItem,
  RadioButton,
  RadioButtonGroup,
  Grid,
  Column
  //@ts-ignore
} from '@carbon/react';

import {
  Add
  // @ts-ignore
} from '@carbon/icons-react';

interface iProps {
  marketVariables: Array<string>;
  view: MutableRefObject<__esri.MapView>;
  definitionExpression: string;
  favoriteProperties?: Set<number>;
  favoriteProperty: (propertyId: number) => void;
  unfavoriteProperty: (propertyId: number) => void;
  user: __esri.PortalUser | null;
  notesTable: MutableRefObject<__esri.FeatureLayer | undefined>;
  notedProperties: Set<number>;
  addNoteToProperty: (note: string, propertyId: number) => Promise<any> | null;
  deleteNote: (objectId: number) => void;
  updateNote: (objectId: number, newNoteText: string) => Promise<any> | null;
  boundary?: __esri.Geometry;
  filterByMapExtent: boolean;
  onClickToPropList: Function;
}

//UPDATES FROM NATALIE: Dropdown nomenclature
const sortOptions = ['Recommended', 'Lot size (acres)']; //used to be Area (acres)
//END UPDATES FROM NATALIE
const propertiesThreshold = 10000;
const propertiesPageSize = 200;
export default function PropertyList(props: iProps) {
  const location = useLocation();
  const propertyLayer = useRef<__esri.FeatureLayer>();
  //UPDATES FROM DALTON: Zoom to OpCO
  const operatingCompanyLayer = useRef<__esri.FeatureLayer>();
  //END UPDATES FROM DALTON   
  const propertyLayerView = useRef<__esri.FeatureLayerView>();
  const [propertyLayerLoaded, setPropertyLayerLoaded] = useState(false);
  const [sortOption, setSortOption] = useState("Recommended");
  const [propertiesCount, setPropertiesCount] = useState<number>();
  const [properties, setProperties] = useState<Property[]>([]);
  const [expandedProperty, setExpandedProperty] = useState<Property | null>(null);
  const [loadingProperties, setLoadingProperties] = useState(false);
  const [loadingPropertiesCount, setLoadingPropertiesCount] = useState(false);
  const [mapExtent, setMapExtent] = useState(props.view.current.extent);
  const highlightHandles = useRef<{ [key: number]: __esri.Handle }>({});

  // csv export
  const [savedPropertiesExportCount, setSavedPropertiesExportCount] = useState<number>();
  const [showCSVExportModal, setShowCSVExportModal] = useState(false);
  const [selectedCSVPropertyOption, setSelectedCSVPropertyOption] = useState('all-properties');
  const csvPropertyFieldsOptions = [
    'Street address',
    'Property manager',
    'Leasing company',
    'Property owner',
    'Property type',
    'Property subtype',
    'Lot size',
    'Building size',
    'Drive time from nearest branch',
    'Amenities list',
    'Parking spaces'
  ];
  const [selectedCSVPropertyFieldsOptions, setSelectedCSVPropertyFieldsOptions] = useState(new Set(csvPropertyFieldsOptions));

  // pdf download
  // const pdfContent = useRef<HTMLDivElement>(null);
  const [showPDFDownloadModal, setShowPDFDownloadModal] = useState(false);
  const [selectedPDFPropertyOption, setSelectedPDFPropertyOption] = useState('all-properties');
  const pdfPropertyFieldsOptions = [
    'Street address',
    'Property manager',
    'Leasing company',
    'Property owner',
    'Property type',
    'Property subtype',
    'Lot size',
    'Google Street View',
    'Building size',
    'Drive time from nearest branch',
    'Amenities list',
    'Parking spaces'
  ];
  const [selectedPDFPropertyFieldsOptions, setSelectedPDFPropertyFieldsOptions] = useState(new Set(pdfPropertyFieldsOptions));

  useEffect(() => {
    props.view.current?.when(() => {
      setMapExtent(props.view.current.extent);
      const propLayer = props.view.current.map.findLayerById('Property');
      if (propLayer) {
        propertyLayer.current = propLayer as __esri.FeatureLayer;
        props.view.current.whenLayerView(propLayer).then((layerView) => {
          propertyLayerView.current = layerView as __esri.FeatureLayerView;
        })
        setPropertyLayerLoaded(true);
      }
    });
  }, [props.view])

  useEffect(() => {
    let handle: __esri.WatchHandle;
    if (props.filterByMapExtent) {
      handle = reactiveUtils.when(() => !props.view.current.updating, () => {
        //console.log("reactive")
        if (props.view.current.extent && props.filterByMapExtent) {
          setMapExtent(props.view.current.extent);
        }
      });
    }

    return () => {
      handle?.remove();
    }
  }, [props.view, props.filterByMapExtent])


  useEffect(() => {
    if (location.state && (location.state as any).property) {
      const property = (location.state as any).property as Property;
      setExpandedProperty(property);
      if (property.latitude && property.longitude) {

        const point = new Point({
          latitude: property.latitude,
          longitude: property.longitude
        });

        props.view.current.when(() => {
          // highlight
          const propLayer = props.view.current.map.findLayerById('Property');
          if (propLayer) {
            props.view.current.whenLayerView(propLayer).then((layerView) => {
              if (property.objectId) {
                highlightHandles.current[property.objectId] = (layerView as __esri.FeatureLayerView).highlight([property.objectId])
              }
            })
          }

          // zoom to when view is done updating
          reactiveUtils.when(() => !props.view.current.updating, () => {
            props.view.current.goTo({
              target: point,
              zoom: 20
            }).catch(error => {
              if (error.name !== "AbortError") {
                console.error(error);
              }
            });
          }, {once: true})
        });
      }
    }
  }, [location.state, props.view])

  //props.view.current.navigation.momentumEnabled = false;
  //props.view.current.navigation.mouseWheelZoomEnabled = false;
  //useEffect(() => {

  //function scrolling(response: __esri.HitTestResult ) {
  /*props.view.current.on("double-click", (event) => {
    event.stopPropagation();
    props.view.current.navigation.momentumEnabled = false;
  });*/
  //}
  //})


  /*props.view.current.surface.addEventListener("wheel", function(event) {   
    event.stopImmediatePropagation();  
  }, true); */

  //UPDATES FROM DALTON: on click white circle
  const Property = props.view.current.map.findLayerById('Property');
  if (Property) {
    operatingCompanyLayer.current = Property as __esri.FeatureLayer;
  }


  useEffect(() => {
    function getGraphics(response: __esri.HitTestResult) {
      // Remove graphics from current response layer
      props.view.current.graphics.removeAll();
      if (response.results.length > 0) {
        
        // Create a graphic if the hitTest responds with > 0 results
        const hit = response.results[0] as any;
        const graphic = hit.graphic;
        var propertysymbol = new PictureMarkerSymbol({
          //"url": "https://heartlandco.maps.arcgis.com/sharing/rest/content/items/17f3488b0fe2433f87b0d3f483e6429f/data",
          "url": "https://heartlandco.maps.arcgis.com/sharing/rest/content/items/fde5e44b5e69462e9eae5bc922bf7055/data",
          //"url":"https://heartlandco.maps.arcgis.com/sharing/rest/content/items/b6daa80ee77c40768fe7f9c2c46af964/data",
          "height": 23.42105263157895,
          //"width":25
          "width": 30
        }); graphic.symbol = propertysymbol
        // Add the graphic to the clicked feature within the properties layer
        props.view.current.graphics.add(graphic);
      }
    }
    props.view.current.when(function () {
      props.view.current.whenLayerView(Property).then(function (lview) {
        reactiveUtils.when(() => !lview.updating, function () {
          // Set up a click event handler
          props.view.current.on("click", (event) => {
            // Create hitTest for Property layer
            props.view.current.hitTest(event, {include: Property}).then((response) => {
                // Callback to getGraphics function with hitTest response
                getGraphics(response);
              });
          });
        }, {once: true});
      });
    })}, [location.state, props.view])
  //END UPDATES FROM DALTON

  //UPDATES FROM DALTON: Zoom to OpCo
  const OpCoLayer = props.view.current.map.findLayerById('OpCoLayer');
  if (OpCoLayer) {
    operatingCompanyLayer.current = OpCoLayer as __esri.FeatureLayer;
  }

  useEffect(() => {

    props.view.current.on("click", (event) => {
      //props.view.current.hitTest(event, OpCoLayer).then((response) => {
      props.view.current.hitTest(event, { include: OpCoLayer }).then((response) => {
        if (response.results.length) {
          const hit = response.results[0] as any;
          const marketgraphic = hit.graphic;
          const graphicID = marketgraphic.attributes?.OBJECTID;
          if (graphicID) {
            properties.find((property) => {
              return property.objectId === graphicID;
            });
            props.view.current.when(() => {
              props.view.current.goTo({
                target: marketgraphic,
                zoom: 8,
              }).catch(error => {
                if (error.name !== "AbortError") {
                  console.error(error);
                }
              });
            })
          }
        }
      }
      )
    });
  }, [location.state, props.view])
  //END UPDATES FROM DALTON 

  //UPDATES FROM DALTON: on hover red pin with white
  function getGraphics1(screenPoint: Point | null) {
    // Remove graphics from current response layer
    props.view.current.graphics.removeAll();
    if (screenPoint !== null) {
      // Create a graphic if the hitTest responds with > 0 results
      var propertysymbol = new PictureMarkerSymbol({
        "url": "https://heartlandco.maps.arcgis.com/sharing/rest/content/items/fde5e44b5e69462e9eae5bc922bf7055/data",
        //"url":"https://heartlandco.maps.arcgis.com/sharing/rest/content/items/b6daa80ee77c40768fe7f9c2c46af964/data",
        "height": 23.42105263157895,
        //"width":25
        width: 30
      });
      var pointGraphic = new Graphic({
        geometry: screenPoint,
        symbol: propertysymbol
      });
      // Add the graphic to the clicked feature within the properties layer
      props.view.current.graphics.add(pointGraphic);
    }
  }

  const highlightItem = (property: Property) => () => {
    if (property.objectId && highlightHandles.current && propertyLayerView.current) {
      if (highlightHandles.current[property.objectId]) return;
      highlightHandles.current[property.objectId] = propertyLayerView.current.highlight([property.objectId]);
      if (property.latitude && property.longitude) {
        var longitude = property.longitude
        var latitude = property.latitude
        const screenPoint = new Point({
          longitude: longitude,
          latitude: latitude
        });
            /*props.view.current.hitTest(screenPoint, {include: Property}).then((response) => {
              // Callback to getGraphics function with hitTest response
              getGraphics1(response);
            })*/getGraphics1(screenPoint);
      };
    }
  }

  const removeHighlight = (property: Property) => () => {
    if (property.objectId) {
      const handle = highlightHandles.current[property.objectId];
      handle?.remove()
      delete highlightHandles.current[property.objectId];
    }
    //UPDATES FROM NATALIE: on click white
    props.view.current.graphics.removeAll();
    //END UPDATES FROM NATALIE    
  }

  useEffect(() => {
    // opens property in details panel
    // if (!mapClickListenerCreated.current) {
    // mapClickListenerCreated.current = true;

    const handle = props.view.current.on("click", (event) => {
      props.view.current.hitTest(event, { include: propertyLayer.current }).then((response) => {
        if (response.results.length) {
          const hit = response.results[0] as any;
          const graphic = hit.graphic;

          const graphicID = graphic.attributes?.OBJECTID;
          if (graphicID) {
            let property = properties.find((property) => {
              return property.objectId === graphicID;
            });

            Object.keys(highlightHandles.current)
              .forEach((id: any) => {
                if (id !== graphicID) {
                  highlightHandles.current[id].remove();
                  delete highlightHandles.current[id];
                }
              });
            if (property) {
              setExpandedProperty(property);
              highlightItem(property)();
              props.onClickToPropList();
            } else {
              // property may not be in properties list, query layer for it
              propertyLayer.current?.queryFeatures({

                where: `OBJECTID = ${graphicID}`,
                outFields: [
                  "PropertyID",
                  "Property_Name",
                  "Property_Address",
                  "City",
                  "State",
                  "Zip",
                  "Property_Type_Main",
                  "Land_Area__AC_",
                  "RBA",
                  "Rank",
                  "Property_Manager_Name",
                  "Property_Manager_Contact",
                  "Property_Manager_Phone",
                  "Leasing_Company_Name",
                  "Leasing_Company_Contact",
                  "Leasing_Company_Phone",
                  "Owner_Name",
                  "Owner_Contact",
                  "Owner_Phone",
                  "Amenities",
                  "Number_Of_Parking_Spaces",
                  "Secondary_Type",
                  "GoogleStreetView",
                  "GoogleSearch",
                  "GoogleMaps",
                  "BingStreetView",
                  "Closest_Branch",
                  "Customer",
                  "ToBreak",
                  "OpCo",
                  "OBJECTID",
                  "Submarket_Cluster",
                  "Latitude",
                  "Longitude"
                ],
                returnGeometry: false,
                num: 1
              }).then(featureSet => {
                if (featureSet.features.length) {
                  const property = mapFeatureToProperty(featureSet.features[0])
                  setExpandedProperty(property);
                  highlightItem(property)();
                  props.onClickToPropList();
                }
              });

            }
          }
        }
      })
    });
    // }
    return () => {
      handle.remove();
    }
  }, [props.view, properties]);

  const getFilterGeometry = useCallback(() => {
    let geometry: __esri.Geometry | undefined = props.boundary;
    if (props.filterByMapExtent && props.boundary && mapExtent) {
      geometry = intersect(mapExtent, props.boundary) as __esri.Geometry;
    } else if (props.filterByMapExtent && !props.boundary) {
      geometry = mapExtent;
    }
    return geometry;
  }, [props.boundary, props.filterByMapExtent, mapExtent])

  const updatePropertyList = () => {
    const abortController = new AbortController();

    if (propertyLayer.current) {
      const geometry = getFilterGeometry();

      // get total count
      setLoadingProperties(true);
      setLoadingPropertiesCount(true);
      propertyLayer.current.queryFeatureCount({
        where: props.definitionExpression,
        geometry: geometry
      }, {signal: abortController.signal}).then((count) => {
        setPropertiesCount(count);
        setLoadingPropertiesCount(false);
        if (count <= propertiesThreshold) {
          const orderBy = sortOption === 'Recommended' ? 'Rank DESC' : 'Land_Area__AC_ DESC';
          propertyLayer.current?.queryFeatures({
            where: props.definitionExpression,
            outFields: [
              "PropertyID",
              "Property_Name",
              "Property_Address",
              "City",
              "State",
              "Zip",
              "Property_Type_Main",
              "Land_Area__AC_",
              "RBA",
              "Rank",
              "Property_Manager_Name",
              "Property_Manager_Contact",
              "Property_Manager_Phone",
              "Leasing_Company_Name",
              "Leasing_Company_Contact",
              "Leasing_Company_Phone",
              "Owner_Name",
              "Owner_Contact",
              "Owner_Phone",
              "Amenities",
              "Number_Of_Parking_Spaces",
              "Secondary_Type",
              "GoogleStreetView",
              "GoogleSearch",
              "GoogleMaps",
              "BingStreetView",
              "Closest_Branch",
              "Customer",
              "ToBreak",
              "OpCo",
              "OBJECTID",
              "Submarket_Cluster",
              "Latitude",
              "Longitude"
            ],
            orderByFields: [orderBy, 'Property_Name ASC', 'Property_Address ASC'],
            returnGeometry: false,
            geometry: geometry,
            num: propertiesPageSize
          }, {signal: abortController.signal}).then((featureSet) => {
            const _properties = featureSet.features.map(mapFeatureToProperty);

            setProperties(_properties);
            setLoadingProperties(false);
          }).catch(err => {
            console.log(err);
          });
        } else {
          setProperties([]);
          setLoadingProperties(false);

          // TODO: warn user
        }
      }).catch(err => {
        console.log(err);
      });
    }

    return () => {
      abortController.abort();
    }
  }
  useEffect(updatePropertyList,
    [
      props.definitionExpression,
      props.filterByMapExtent,
      sortOption,
      props.boundary,
      propertyLayerLoaded,
      // mapExtent,
      getFilterGeometry
    ]
  );

  const loadMoreProperties = () => {
    const abortController = new AbortController();
    if (propertyLayer.current) {
      setLoadingProperties(true);

      const geometry = getFilterGeometry();
      if (properties.length <= propertiesThreshold) {
        const orderBy = sortOption === 'Recommended' ? 'Rank DESC' : 'Land_Area__AC_ DESC';
        propertyLayer.current?.queryFeatures({
          where: props.definitionExpression,
          outFields: [
            "PropertyID",
            "Property_Name",
            "Property_Address",
            "City",
            "State",
            "Zip",
            "Property_Type_Main",
            "Land_Area__AC_",
            "RBA",
            "Rank",
            "Property_Manager_Name",
            "Property_Manager_Contact",
            "Property_Manager_Phone",
            "Leasing_Company_Name",
            "Leasing_Company_Contact",
            "Leasing_Company_Phone",
            "Owner_Name",
            "Owner_Contact",
            "Owner_Phone",
            "Amenities",
            "Number_Of_Parking_Spaces",
            "Secondary_Type",
            "GoogleStreetView",
            "GoogleSearch",
            "GoogleMaps",
            "BingStreetView",
            "Closest_Branch",
            "Customer",
            "ToBreak",
            "OpCo",
            "OBJECTID",
            "Submarket_Cluster",
            "Latitude",
            "Longitude"
          ],
          orderByFields: [orderBy, 'Property_Name ASC', 'Property_Address ASC'],
          returnGeometry: false,
          geometry: geometry,
          num: propertiesPageSize,
          start: properties.length
        }, { signal: abortController.signal }).then((featureSet) => {
          const _properties = [...properties, ...featureSet.features.map(mapFeatureToProperty)];
          setProperties(_properties);
          setLoadingProperties(false);
        }).catch(err => {
          console.log(err);
        });
      } else {
        setProperties([]);
        setLoadingProperties(false);

        // TODO: warn user
      }
    }
  }

  const mapFeatureToProperty = (feature: __esri.Graphic) => {
    const atts = feature.attributes;
    return {
      id: atts.PropertyID,
      name: atts.Property_Name,
      address: atts.Property_Address,
      city: atts.City,
      state: atts.State,
      zip: atts.Zip,
      type: atts.Property_Type_Main,
      acres: atts.Land_Area__AC_,
      buildingArea: atts.RBA,
      rank: atts.Rank,
      propertyManagerName: atts.Property_Manager_Name,
      propertyManagerContact: atts.Property_Manager_Contact,
      propertyManagerPhone: atts.Property_Manager_Phone,
      leasingCompanyName: atts.Leasing_Company_Name,
      leasingCompanyContact: atts.Leasing_Company_Contact,
      leasingCompanyPhone: atts.Leasing_Company_Phone,
      ownerName: atts.Owner_Name,
      ownerContact: atts.Owner_Contact,
      ownerPhone: atts.Owner_Phone,
      amenities: atts.Amenities,
      parkingSpaces: atts.Number_Of_Parking_Spaces,
      type_secondary: atts.Secondary_Type,
      googleStreetView: atts.GoogleStreetView,
      googleSearch: atts.GoogleSearch,
      googleMaps: atts.GoogleMaps,
      bingStreetView: atts.BingStreetView,
      closestBranch: atts.Closest_Branch,
      customer: atts.Customer,
      driveTime: atts.ToBreak,
      opco: atts.OpCo,
      objectId: atts.OBJECTID,
      submarketCluster: atts.Submarket_Cluster,
      latitude: atts.Latitude,
      longitude: atts.Longitude
    } as Property
  }

  const onSortOptionChange = (evt: any) => {
    setSortOption(evt.selectedItem);
  }

  const expandItem = (property: Property) => () => {
    setExpandedProperty(property);
    highlightItem(property)();
  }

  const closeExpandedItem = () => {
    if (expandedProperty) removeHighlight(expandedProperty)();
    setExpandedProperty(null);
  }


  // csv export
  const calculateSavedPropertiesExportCount = () => {
    if (!propertyLayer.current) return;

    if (!props.favoriteProperties || props.favoriteProperties.size === 0) {
      setSavedPropertiesExportCount(0);
      return;
    }

    if (propertyLayer.current.definitionExpression.includes('PropertyID IN')) {
      setSavedPropertiesExportCount(propertiesCount);
    } else {
      const savedPropertiesExpression = `PropertyID IN (${Array.from(props.favoriteProperties).join(',')})`;
      let where = savedPropertiesExpression;
      if (propertyLayer.current.definitionExpression) {
        where = `${propertyLayer.current.definitionExpression} AND (${savedPropertiesExpression})`;
      }
      const geometry = getFilterGeometry();
      propertyLayer.current.queryFeatureCount({
        where,
        geometry: geometry
      }).then(count => setSavedPropertiesExportCount(count));
    }
  }

  const openCSVExportModal = () => {
    calculateSavedPropertiesExportCount();
    setShowCSVExportModal(true);
  }

  const closeCSVExportModal = () => {
    setShowCSVExportModal(false);
  }

  const onCSVPropertyOptionRadioChange = (evt: any) => {
    setSelectedCSVPropertyOption(evt);
  }

  const onCSVPropertyFieldCheckboxChange = (evt: any) => {
    const options = new Set(selectedCSVPropertyFieldsOptions);
    if (evt.target.checked) {
      options.add(evt.target.value);
    } else {
      options.delete(evt.target.value);
    }
    setSelectedCSVPropertyFieldsOptions(options);
  }

  const getExportData = (propertyOption: string, fieldsOptions: Set<string>) => {

    if (!propertyLayer.current) return;
    let where = propertyLayer.current.definitionExpression;
    if (propertyOption === 'only-saved-properties' && !where.includes('PropertyID IN') && props.favoriteProperties) {
      const savedPropertiesExpression = `PropertyID IN (${Array.from(props.favoriteProperties).join(',')})`;
      where = `${propertyLayer.current.definitionExpression} AND (${savedPropertiesExpression})`;
    }
    const pageSize = 2000;

    const columns: string[] = ["Property_Name"];
    if (fieldsOptions.has('Street address')) {
      columns.push('Property_Address');
      columns.push('City');
      columns.push('State');
      columns.push('Zip');
    }
    if (fieldsOptions.has('Property manager')) {
      columns.push('Property_Manager_Name');
      columns.push('Property_Manager_Contact');
      columns.push('Property_Manager_Phone');
    }
    if (fieldsOptions.has('Leasing company')) {
      columns.push('Leasing_Company_Name');
      columns.push('Leasing_Company_Contact');
      columns.push('Leasing_Company_Phone');
    }
    if (fieldsOptions.has('Property owner')) {
      columns.push('Owner_Name');
      columns.push('Owner_Contact');
      columns.push('Owner_Phone');
    }
    if (fieldsOptions.has('Property type')) {
      columns.push('Property_Type_Main');
    }
    if (fieldsOptions.has('Property subtype')) {
      columns.push('Secondary_Type');
    }
    if (fieldsOptions.has('Lot size')) {
      columns.push('Land_Area__AC_');
    }
    if (fieldsOptions.has('Building size')) {
      columns.push('RBA');
    }
    if (fieldsOptions.has('Drive time from nearest branch')) {
      columns.push('ToBreak');
      columns.push('Closest_Branch');
    }
    if (fieldsOptions.has('Amenities list')) {
      columns.push('Amenities');
    }
    if (fieldsOptions.has('Parking spaces')) {
      columns.push('Number_Of_Parking_Spaces');
    }
    if (fieldsOptions.has('Google Street View')) {
      columns.push('GoogleStreetView');
    }

    const geometry = getFilterGeometry();

    // initial query for feature count used to determine number of pages
    return new Promise<{ attributes: any[], columns: string[] }>((resolve, reject) => {
      propertyLayer.current?.queryFeatureCount({
        where,
        geometry: geometry
      }).then(count => {
        // query features in pages
        const orderBy = sortOption === 'Recommended' ? 'Rank DESC' : 'Land_Area__AC_ DESC';
        const pages = Math.ceil(count / pageSize);
        const promises = [];
        for (let page = 0; page < pages; page++) {
          if (propertyLayer.current) {
            const promise = propertyLayer.current.queryFeatures({
              where,
              geometry: geometry,
              outFields: columns,
              returnGeometry: false,
              orderByFields: [orderBy, 'Property_Name ASC', 'Property_Address ASC'],
              num: pageSize,
              start: page * pageSize
            });
            promises.push(promise);
          }
        }

        // when all paged data has returned, create csv from feature attributes
        eachAlways(promises).then((responses: any[]) => {
          let allAtts: any[] = [];
          responses.forEach(response => {
            if (response.value) {
              const featureSet = response.value as __esri.FeatureSet;
              const atts = featureSet.features.map(feature => feature.attributes);
              allAtts = allAtts.concat(atts);
            }
          });

          resolve({ attributes: allAtts, columns });
        });
      });
    })
  }

  const exportToCSV = () => {
    if (!propertyLayer.current) return;

    getExportData(selectedCSVPropertyOption, selectedCSVPropertyFieldsOptions)?.then(response => {
      const attributes = response.attributes;
      const columns = response.columns;
      const replacer = (value: any, fieldName: string) => {
        if (value === null || value === undefined) {
          return '';
        }
        if (typeof value === 'string') {
          return `"${value}"`;
        }
        return value
      };

      const csvArray = attributes.map(atts => {
        return columns.map(fieldName => {
          return replacer(atts[fieldName], fieldName)
        }).join(',')
      });
      csvArray.unshift(columns.join(','));

      const blob = new Blob([csvArray.join('\r\n')], { type: 'text/csv;charset=utf-8' });

      if (blob.size > 500000000) {
        alert("The amount of data requested is too large to export to CSV.");
        return;
      } else {
        const now = new Date(Date.now());
        const fName = `SmartReach_Export_${now.toLocaleDateString()}_${now.toLocaleTimeString()}`
          .replace(/\s/g, '');
        ;
        saveAs(blob, fName);
      }
    });

    closeCSVExportModal();
  }

  // pdf download
  const openPDFDownloadModal = () => {
    calculateSavedPropertiesExportCount()
    setShowPDFDownloadModal(true);
  }

  const closePDFDownloadModal = () => {
    setShowPDFDownloadModal(false);
  }

  const onPDFPropertyOptionRadioChange = (evt: any) => {
    setSelectedPDFPropertyOption(evt);
  }

  const onPDFPropertyFieldCheckboxChange = (evt: any) => {
    const options = new Set(selectedPDFPropertyFieldsOptions);
    if (evt.target.checked) {
      options.add(evt.target.value);
    } else {
      options.delete(evt.target.value);
    }
    setSelectedPDFPropertyFieldsOptions(options);
  }

  const downloadPDF = () => {

    getExportData(selectedPDFPropertyOption, selectedPDFPropertyFieldsOptions)?.then(response => {
      const properties = response.attributes.map(atts => {
        return {
          id: atts.PropertyID,
          name: atts.Property_Name,
          address: atts.Property_Address,
          city: atts.City,
          state: atts.State,
          zip: atts.Zip,
          type: atts.Property_Type_Main,
          acres: atts.Land_Area__AC_,
          buildingArea: atts.RBA,
          propertyManagerName: atts.Property_Manager_Name,
          propertyManagerContact: atts.Property_Manager_Contact,
          propertyManagerPhone: atts.Property_Manager_Phone,
          leasingCompanyName: atts.Leasing_Company_Name,
          leasingCompanyContact: atts.Leasing_Company_Contact,
          leasingCompanyPhone: atts.Leasing_Company_Phone,
          ownerName: atts.Owner_Name,
          ownerContact: atts.Owner_Contact,
          ownerPhone: atts.Owner_Phone,
          amenities: atts.Amenities,
          parkingSpaces: atts.Number_Of_Parking_Spaces,
          type_secondary: atts.Secondary_Type,
          googleStreetView: atts.GoogleStreetView,
          closestBranch: atts.Closest_Branch,
          driveTime: atts.ToBreak,
        } as Property
      });
      const pdfContent: HTMLDivElement = document.createElement('div') as HTMLDivElement;
      render(<>{
        properties.map((property, index) =>
          <PDFProperty
            property={property}
            selectedFieldsOptions={selectedPDFPropertyFieldsOptions}
            key={index} />
        )
      }</>, pdfContent, () => {
        const printWindow = window.open('', 'print', 'height=800, width=800');

        const styles = document.head.getElementsByTagName("style");
        const links = document.head.getElementsByTagName("link");

        printWindow?.document.write('<html><head><title>SmartReach</title>');
        Array.from(styles).forEach(element => {
          printWindow?.document.write(element.outerHTML);
        });
        Array.from(links).forEach(element => {
          if (element.rel === 'stylesheet') {
            printWindow?.document.write(element.outerHTML);
          }
        });
        printWindow?.document.write('</head><body style="overflow: visible">');
        printWindow?.document.write(pdfContent.outerHTML)
        printWindow?.document.write('</body></html>');
        printWindow?.document.close();
        printWindow?.print();
        closePDFDownloadModal();
      });
    });

  }

  const previewProperty = properties.length > 0 ? properties[0] : null;
  const showLoadMoreButton = (!!propertiesCount
    && propertiesCount <= propertiesThreshold
    && properties.length < propertiesCount);

    const emptyFunct = () => {
      "nothing"
    }
  

  return (
    <div className="property-list">
      {expandedProperty === null &&
        <>
          <div className="property-list-header">
            <span className='properties-count'>
              {propertiesCount !== undefined && !loadingPropertiesCount &&
                <span>
                  {!loadingProperties && `${properties.length} of `}
                  {`${propertiesCount.toLocaleString("en-US")} properties`}
                </span>
              }
              {loadingPropertiesCount &&
                <InlineLoading />
              }
            </span>
            <span className="button-group">
              <Dropdown
                id="property-sort"
                label="Property List Sort"
                items={sortOptions}
                initialSelectedItem='Recommended'
                onChange={onSortOptionChange}
                hideLabel={true}
                size='sm'
                itemToString={(item: string) => item}
              />
              <OverflowMenu ariaLabel="Export Data"
                size="sm"
                flipped={true}>
                <OverflowMenuItem onClick={openPDFDownloadModal} itemText="Download PDF..." />
                <OverflowMenuItem onClick={openCSVExportModal} itemText="Export to CSV..." />
              </OverflowMenu>
            </span>
          </div>
          <div className='property-list-items'>
            {!!propertiesCount && (propertiesCount > propertiesThreshold) &&
              // UPDATES FROM DALTON
              <div className="notification-container">
                <ToastNotification
                  className="get-started-notification"
                  kind="info"
                  lowContrast={true}
                  statusIconDescription="Get started"
                  title="View recommended properties"
                  subtitle="Select filters or draw a boundary to reduce the
                total number of properties to less than 10,000."
                // END UPDATES FROM DALTON          
                />
              </div>
              //UPDATES FROM NATALIE: updated message in panel
              /*<div className="warning">
                Select filters or draw boundaries to reduce the total number of properties to less than 10,000.
              </div>*/
              //END UPDATES FROM NATALIE              
            }
            {properties.map((property, index) =>
              <PropertyListItem
                key={index}
                property={property}
                isFavorite={props.favoriteProperties ? props.favoriteProperties.has(property.id) : false}
                hasNote={props.notedProperties.has(property.id)}
                onClick={expandItem(property)}
                onMouseEnter={highlightItem(property)}
                onMouseLeave={removeHighlight(property)}
                removeProperty={emptyFunct}
                renameSavedProperty={null}
              />
            )}
            {showLoadMoreButton &&
              <div className="load-more-container">
                {/*UPDATES FROM NATALIE: Load more button*/}
                <Button renderIcon={Add}
                  onClick={loadMoreProperties}
                  kind="primary"
                  size="lg">
                  Load more properties
                </Button>
                {/*<IconButton
                label="Load more"
                onClick={loadMoreProperties}
                kind="tertiary"
                size="lg">
                <Add />
              </IconButton>*/}
                {/*END UPDATES FROM NATALIE*/}
              </div>
            }
            {loadingProperties &&
              <div className="loading-properties">
                <Loading
                  active={true}
                  withOverlay={false}
                />
              </div>
            }
          </div>
        </>
      }
      {expandedProperty !== null &&
        <PropertyDetails
          view={props.view}
          property={expandedProperty}
          isFavorite={props.favoriteProperties ? props.favoriteProperties.has(expandedProperty.id) : false}
          close={closeExpandedItem}
          notesTable={props.notesTable}
          user={props.user}
          onAddNote={props.addNoteToProperty}
          onDeleteNote={props.deleteNote}
          onUpdateNote={props.updateNote}
          favoriteProperty={props.favoriteProperty}
          unfavoriteProperty={props.unfavoriteProperty}
          propertyLayer={propertyLayer}
          marketVariables={props.marketVariables}
        />
      }
      <Modal
        open={showCSVExportModal}
        modalHeading="Configure and export CSV" //UPDATES FROM NATALIE
        primaryButtonText="Export"
        secondaryButtonText="Cancel"
        onRequestClose={closeCSVExportModal}
        onRequestSubmit={exportToCSV}>
        <p className="mb-1">
          Choose how you prefer to structure your tabular property list:
        </p>
        <RadioButtonGroup
          className='mb-1'
          legendText="Properties"
          name="csv-properties-export-options"
          onChange={onCSVPropertyOptionRadioChange}
          valueSelected={selectedCSVPropertyOption} >
          <RadioButton
            labelText={`All properties (${propertiesCount?.toLocaleString("en-US")})`}
            value="all-properties"
            id="csv-all-properties" />
          <RadioButton
            labelText={`Only saved properties (${savedPropertiesExportCount?.toLocaleString("en-US")})`}
            value="only-saved-properties"
            id="csv-saved-properties" />
        </RadioButtonGroup>

        <fieldset>
          <legend className='cds--label'>Property fields</legend>
          <Grid className='p-0' narrow={true}>
            <Column lg={8} md={4} sm={4}>
              {csvPropertyFieldsOptions.slice(0, 6).map((option, index) =>
                <Checkbox
                  key={index}
                  labelText={option}
                  value={option}
                  id={`csv-property-field-right-${index}`}
                  checked={selectedCSVPropertyFieldsOptions.has(option)}
                  onChange={onCSVPropertyFieldCheckboxChange} />
              )}
            </Column>
            <Column lg={8} md={4} sm={4}>
              {csvPropertyFieldsOptions.slice(6).map((option, index) =>
                <Checkbox
                  key={index}
                  labelText={option}
                  value={option}
                  id={`csv-property-field-left-${index}`}
                  checked={selectedCSVPropertyFieldsOptions.has(option)}
                  onChange={onCSVPropertyFieldCheckboxChange} />
              )}
            </Column>
          </Grid>
        </fieldset>
      </Modal>

      <Modal
        open={showPDFDownloadModal}
        modalHeading="Configure and download PDF" //UPDATES FROM NATALIE: used to be "Configure print layout"
        primaryButtonText="Download"
        secondaryButtonText="Cancel"
        onRequestClose={closePDFDownloadModal}
        onRequestSubmit={downloadPDF}>
        <p className="mb-1">
          Choose how you prefer to structure your printable property list:
        </p>
        <RadioButtonGroup
          className='mb-1'
          legendText="Properties"
          name="pdf-properties-export-options"
          onChange={onPDFPropertyOptionRadioChange}
          valueSelected={selectedPDFPropertyOption} >
          <RadioButton
            labelText={`All properties (${propertiesCount?.toLocaleString("en-US")})`}
            value="all-properties"
            id="pdf-all-properties" />
          <RadioButton
            labelText={`Only saved properties (${savedPropertiesExportCount?.toLocaleString("en-US")})`}
            value="only-saved-properties"
            id="pdf-saved-properties" />
        </RadioButtonGroup>

        <fieldset className="mb-1">
          <legend className='cds--label'>Property fields</legend>
          <Grid className='p-0' narrow={true}>
            <Column lg={8} md={4} sm={4}>
              {pdfPropertyFieldsOptions.slice(0, 7).map((option, index) =>
                <Checkbox
                  key={index}
                  labelText={option}
                  value={option}
                  id={`pdf-property-field-right-${index}`}
                  checked={selectedPDFPropertyFieldsOptions.has(option)}
                  onChange={onPDFPropertyFieldCheckboxChange} />
              )}
            </Column>
            <Column lg={8} md={4} sm={4}>
              {pdfPropertyFieldsOptions.slice(7).map((option, index) =>
                <Checkbox
                  key={index}
                  labelText={option}
                  value={option}
                  id={`pdf-property-field-left-${index}`}
                  checked={selectedPDFPropertyFieldsOptions.has(option)}
                  onChange={onPDFPropertyFieldCheckboxChange} />
              )}
            </Column>
          </Grid>
        </fieldset>
        {previewProperty &&
          <div>
            <div className="cds--label">Property item preview</div>
            <div className="pdf-preview">
              <PDFProperty property={previewProperty} selectedFieldsOptions={selectedPDFPropertyFieldsOptions} />
            </div>
          </div>
        }
      </Modal>
    </div>
  );
}

function PDFProperty(props: { property: Property, selectedFieldsOptions: Set<string> }) {

  return (
    <Grid className="pdf-property">
      <Column className="mb-1" lg={8} md={4} sm={2}>
        <div className="property-name">{props.property.name ?? props.property.address}</div>
        {props.selectedFieldsOptions.has('Street address') &&
          <div>{props.property.address}, {props.property.city}, {props.property.state} {props.property.zip}</div>
        }
      </Column>
      {props.selectedFieldsOptions.has('Property manager') &&
        <Column className="mb-1" lg={4} md={2} sm={1}>
          <div className="cds--label m-0">Property manager</div>
          <div>{props.property.propertyManagerName}</div>
          <div>{formatPhoneNumber(props.property.propertyManagerPhone)}</div>
        </Column>
      }
      {props.selectedFieldsOptions.has('Leasing company') &&
        <Column className="mb-1" lg={4} md={2} sm={1}>
          <div className="cds--label m-0">Leasing company</div>
          <div>{props.property.leasingCompanyName}</div>
          <div>{formatPhoneNumber(props.property.leasingCompanyPhone)}</div>
        </Column>
      }
      {props.selectedFieldsOptions.has('Property owner') &&
        <Column className="mb-1" lg={4} md={2} sm={1}>
          <div className="cds--label m-0">Property owner</div>
          <div>{props.property.ownerName}</div>
          <div>{formatPhoneNumber(props.property.ownerPhone)}</div>
        </Column>
      }
      {(props.selectedFieldsOptions.has('Property type') || props.selectedFieldsOptions.has('Property subtype')) &&
        <Column className="mb-1" lg={4} md={2} sm={1}>
          <div className="cds--label m-0">Property type</div>
          <div>
            {props.selectedFieldsOptions.has('Property type') &&
              <span>{props.property.type}</span>
            }
            {(props.selectedFieldsOptions.has('Property type') && props.selectedFieldsOptions.has('Property subtype')) &&
              <span>: </span>
            }
            {props.selectedFieldsOptions.has('Property subtype') &&
              <span>{props.property.type_secondary} </span>
            }
          </div>
        </Column>
      }
      {props.selectedFieldsOptions.has('Lot size') &&
        <Column className="mb-1" lg={4} md={2} sm={1}>
          <div className="cds--label m-0">Lot size</div>
          <div>{roundString(props.property.acres, 1)} acres</div>
        </Column>
      }
      {props.selectedFieldsOptions.has('Google Street View') &&
        <Column className="mb-1" lg={4} md={2} sm={1}>
          <div className="cds--label m-0">Google Street View</div>
          <div>
            <a target="_blank" rel="noreferrer" href={props.property.googleStreetView ?? ""}>Link</a>
          </div>
        </Column>
      }
      {props.selectedFieldsOptions.has('Building size') &&
        <Column className="mb-1" lg={4} md={2} sm={1}>
          <div className="cds--label m-0">Building size</div>
          <div>{props.property.buildingArea?.toLocaleString('en-US')} sq ft</div>
        </Column>
      }
      {props.selectedFieldsOptions.has('Drive time from nearest branch') &&
        <Column className="mb-1" lg={4} md={2} sm={1}>
          <div className="cds--label m-0">Drive time</div>
          <div>{props.property.driveTime} minutes from {props.property.closestBranch}</div>
        </Column>
      }
      {props.selectedFieldsOptions.has('Amenities list') &&
        <Column className="mb-1" lg={4} md={2} sm={1}>
          <div className="cds--label m-0">Amenities</div>
          <div>{props.property.amenities}</div>
        </Column>
      }
      {props.selectedFieldsOptions.has('Parking spaces') &&
        <Column className="mb-1" lg={4} md={2} sm={1}>
          <div className="cds--label m-0">Parking spaces</div>
          <div>{props.property.parkingSpaces}</div>
        </Column>
      }
    </Grid>

  );
}