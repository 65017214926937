import React, { useState, MutableRefObject, useEffect, useRef, useCallback } from "react";
import './filters.scss';
import FilterDropdown from "./filter-dropdown/FilterDropdown"
import { useLocation, useNavigate } from "react-router-dom";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import Session from "../../../models/session";
import SessionFilters from "../../../models/session-filters";
import ToolTip from "../../shared/tool-tip/ToolTip";
import HOA from '../../../images/HOA_edited.png';
import Property from "../../../models/property";
import Query from "@arcgis/core/rest/support/Query";
//import MHIlayer from "C:/Users/Dalton Silhan/Desktop/SR3.0/For Dalton/smartreach/src/components/prospecting/mapConfig"

import {
  Accordion,
  AccordionItem,
  ActionableNotification,
  Button,
  IconButton,
  Checkbox,
  Modal,
  MultiSelect,
  NumberInput,
  Select,
  SelectItem,
  Tag,
  TextInput,
  ToastNotification,
  Toggle,
  InlineLoading,
  Tooltip
  //@ts-ignore
} from '@carbon/react';

import {
  Reset,
  Save,
  //UPDATES FROM NATALIE: Icon for Aspire filter
  TrophyFilled,
  Collaborate,
  Download,
  //END UPDATES FROM NATALIE
  //@ts-ignore
} from '@carbon/icons-react';
import { eachAlways } from "@arcgis/core/core/promiseUtils";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import MapView from "@arcgis/core/views/MapView";

interface iProps {
  view: MutableRefObject<__esri.MapView>;
  //property: Property;
  propertyBaseFilterExpression: string;
  crmRecordBaseFilterExpression: string;
  contractBaseFilterExpression: string;
  //MHIBaseFilterExpression: string;
  setPropertyDefinitionExpression: (value: string) => void;
  filterByMapExtent: boolean;
  setFilterByMapExtent: (value: boolean) => void;
  favoriteProperties?: Set<number>;
  boundary?: __esri.Geometry;
  savedSessionsUrl: string;
  user: __esri.PortalUser | null;
  filtersStorageKey: string;
}

type ContactType = 'property-manager' | 'leasing-company' | 'property-owner';

// update from lydia
type PropertyType = 'Flex' | 'Health Care' | 'Hospitality' | 'Industrial' |
  'Multi-Family' | 'Office' | 'Retail' | 'Specialty' |
  'Sports & Entertainment' | 'Student';

const filtersStorageKey = 'smartreach-filters';
// end update from lydia

export default function Filters(props: iProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const propertyLayer = useRef<__esri.FeatureLayer>();
  const marketSummaryLayer = useRef<__esri.FeatureLayer>();
  const crmRecordLayer = useRef<__esri.FeatureLayer>();
  const hoaLayer = useRef<__esri.FeatureLayer>();
  const hoaMarketLayer = useRef<__esri.FeatureLayer>();
  const contractGroupLayer = useRef<__esri.GroupLayer>();
  const ParcelTileLayer = useRef<__esri.TileLayer>();
  const MHI = useRef<__esri.MapImageLayer>();
  const driveTimeLayer = useRef<__esri.FeatureLayer>();
  const contractPropertyLayer = useRef<__esri.FeatureLayer>();
  const contractHeatMapLayer = useRef<__esri.FeatureLayer>();
  const sessionLoaded = useRef(false);
  const filterOptionsInitialized = useRef(false);

  // DALTON crm update
  const circle = useRef<__esri.Circle | undefined>();
  const [crmFeatures, setCrmFeatures] = useState<__esri.Graphic[] | null>(null);
  /*const {
    name,
    address,
    city,
    state,
    zip,
    rank,
    amenities,
    id,
    parkingSpaces,
    buildingArea,
    acres,
    bingStreetView,
    //UPDATES FROM NATALIE: import GoogleMaps for dropdown
    googleMaps,
    //END UPDATES FROM NATALIE    
    googleStreetView,
    googleSearch,
    ownerName,
    ownerContact,
    ownerPhone,
    propertyManagerName,
    propertyManagerContact,
    propertyManagerPhone,
    leasingCompanyName,
    leasingCompanyContact,
    leasingCompanyPhone,
    //closestBranch,
    driveTime,
    type,
    type_secondary,
    latitude,
    longitude,
    notes,
  } = props.property;*/


  // filter expressions
  const [propertyTypeExpression, setPropertyTypeExpression] = useState('');
  const [lotSizeExpression, setLotSizeExpression] = useState('');
  const [contactExpression, setContactExpression] = useState('');
  const [cityStateZipExpression, setCityStateZipExpression] = useState('');
  const [savedSearchesLoaded, setSavedSearchesLoaded] = useState(false);
  const [locationLoaded, setLocationLoaded] = useState(true);
  const [moreFilterExpressions, setMoreFilterExpressions] = useState({
    property: {
      savedProperties: '',
      customer: '', //UPDATES FROM NATALIE: active
      status: '',
      buildingSize: '',
      class: '',
      parking: '',
      amenities: '',
      closestBranch: '',
      driveTime: '',
      zip: '',
      city: '',
      state: ''
    },
    crmProspects: {
      status: '',
      competitor: ''
    },
    activeContracts: {
      branch: '',
      accountManager: '',
      customer: '',
      marketSegment: ''
    },
    MHI: { // updates DALTON
      MEDHINC_CY: ''
    }
  });

  // property type
  // update from lydia
  const [secondarySelectedPropertyTypes, setSecondarySelectedPropertyTypes] = useState({
    'Flex': [''],
    'Health Care': [''],
    'Hospitality': [''],
    'Industrial': [''],
    'Multi-Family': [''],
    'Office': [''],
    'Retail': [''],
    'Specialty': [''],
    'Sports & Entertainment': [''],
    'Student': ['']
  });

  //UPDATES DALTON

  //let [Update_Type, setType] = useState<string>('');

  let main_type_select: any[] = ['Flex', 'Health Care', 'Hospitality', 'Industrial',
    'Multi-Family', 'Office', 'Retail', 'Specialty',
    'Sports & Entertainment', 'Student']

  //END UPDATES DALTON

  // helper functions for secondarySelectedPropertyTypes inner lists
  // has
  const hasSecondary = (list: string[], value: string) => {
    let isInList = false;
    list.forEach(element => {
      if (element == value) {
        isInList = true;
      }
    });
    return isInList;
  }

  // add
  const addSecondary = (list: string[], value: string) => {
    // remove placeholder string
    if (list[0] == '') {
      list.shift();
    }

    if (!hasSecondary(list, value)) {
      list.push(value);
    }
    return list;
  }

  // delete
  const deleteSecondary = (list: string[], value: string) => {
    return list.filter(data => data != value);
  }

  const [secondaryOptions] = useState({
    'Flex': ["Light Manufacturing", "R&D", "Light Distribution",
      "Showroom", "Telecom Hotel/Data Hosting"],
    'Health Care': ["Assisted Living", "Skilled Nursing Facility",
      "Continuing Care Retirement Community", "Congregate Senior Housing", "Medical",
      "Hospital", "Rehabilitation Center"],
    'Hospitality': ["Hostel", "Serviced Apartment", "Bed and Breakfast", "Hotel"],
    'Industrial': ["Truck Terminal", "Warehouse", "Refrigeration/Cold Storage",
      "Distribution", "Service", "Food Processing", "Manufacturing", "Showroom",
      "Telecom Hotel/Data Hosting"],
    'Multi-Family': ["Apartments", "Dormitory", "Storefront Retail/Residential",
      "Manufactured Housing/Mobile Home Park"],
    'Office': ["Office/Residential", "Office Building", "Industrial Live/Work Unit",
      "Loft/Creative Space", "Office Live/Work Unit", "Telecom Hotel/Data Hosting"],
    'Retail': ["Auto Repair", "Storefront Retail/Office", "Freestanding",
      "Department Store", "Garden Center", "Movie Theatre", "Veterinarian/Kennel",
      "Truck Stop", "Retail Building", "Bank", "Auto Dealership", "Service Station",
      "Health Club", "Quick Service", "Drug Store", "Funeral Home", "Convenience Store",
      "Restaurant", "Storefront", "Day Care Center", "Storefront Retail/Residential",
      "Bowling Alley", "Bar/Nightclub", "Supermarket", "Fast Food"],
    'Specialty': ["Cemetery/Mausoleum", "Railroad Yard", "Winery/Vineyard",
      "Religious Facility", "Marina", "Lodge/Meeting Hall", "Trailer / Camper Park",
      "Radio/TV Transmission Facilities", "Water Treatment Facility", "Auto Salvage Facility",
      "Shelter", "Correctional Facility", "Movie/Radio/TV Studio", "Car Wash",
      "Parking Garage", "Recycling Center", "Parking Lot", "Public Library",
      "Utility Sub-Station", "Airport", "Airplane Hangar", "Drive-in Movie",
      "Self-Storage", "Contractor Storage Yard", "Shipyard", "Chemical/Oil Refinery",
      "Schools", "Police / Fire Station", "Cement/Gravel Plant", "Lumberyard",
      "Sorority / Fraternity House", "Landfill", "Post Office", "Water Retention Facility"],
    'Sports & Entertainment': ["Amusement Park", "Skating Rink", "Casino",
      "Theater/Concert Hall", "Race Track", "Baseball Field", "Golf Course/Driving Range",
      "Swimming Pool", "Horse Stables"],
    'Student': ["Apartments", "Dormitory"]
  });

  const [propertyTypeOptions, setPropertyTypeOptions] = useState<PropertyType[]>([]);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState(new Set<string>());
  // end updates from lydia

  // lot size
  const [lotSizeMin, setLotSizeMin] = useState<number | ''>('')

  // contact
  const contactTypes = useRef<{ label: string; value: ContactType }[]>(
    [
      { label: 'Property manager', value: 'property-manager' },
      { label: 'Leasing company', value: 'leasing-company' },
      { label: 'Property owner', value: 'property-owner' }
    ]
  );
  const [selectedContactTypes, setSelectedContactTypes] = useState(new Set<string>());
  const [contactSearchTerms, setContactSearchTerms] = useState({
    'property-manager': '',
    'leasing-company': '',
    'property-owner': ''
  });

  // more filters
  //// saved properties
  const [showSavedPropertiesOnly, setShowSavePropertiesOnly] = useState(false);

  //// customer UPDATES FROM NATALIE
  const [customerOptions, setCustomerOptions] = useState<string[]>([]);
  const [selectedCustomerOptions, setSelectCustomerOptions] = useState(new Set<string>());

  //// building status
  const [buildingStatusOptions, setBuildingStatusOptions] = useState<string[]>([]);
  const [selectedBuildingStatusOptions, setSelectBuildingStatusOptions] = useState(new Set<string>());

  //// building size
  const [buildingSizeMin, setBuildingSizeMin] = useState<number | ''>('');

  //// building class
  const [buildingClassOptions, setBuildingClassOptions] = useState<string[]>([]);
  const [selectedBuildingClassOptions, setSelectBuildingClassOptions] = useState(new Set<string>());

  const [cityOptions, setCityOptions] = useState<string[]>([]);
  const [selectedCityOptions, setSelectCityOptions] = useState(new Set<string>());

  const [stateOptions, setStateOptions] = useState<string[]>([]);
  const [selectedStateOptions, setSelectStateOptions] = useState(new Set<string>());

  const [zipOptions, setzipOptions] = useState<string[]>([]);
  let [selectedZipOptions, setSelectZipOptions] = useState(new Set<string>());

  //// parking
  const [parkingMin, setParkingMin] = useState<number | ''>('');

  //// amenities
  const [amenitiesSearchText, setAmenitiesSearchText] = useState('');

  //// drive time
  const [branchOptions, setBranchOptions] = useState<string[]>([]);
  // const [selectedBranchOptions, setSelectedBranchOptions] = useState(new Set<string>());
  const [closestBranch, setClosestBranch] = useState('');

  const driveTimeOptions = [5, 10, 15, 20, 30, 60];
  // const [selectedDriveTimeOptions, setSelectedDriveTimeOptions] = useState(new Set<number>());
  const [driveTimeMax, setDriveTimeMax] = useState(0);

  //// CRM prospects
  const [showCRMProspects, setShowCRMProspects] = useState(true);

  const [crmStatusOptions, setCRMStatusOptions] = useState<string[]>([]);
  const [selectedCRMStatusOptions, setSelectedCRMStatusOptions] = useState(new Set<string>());

  const [crmCompetitorOptions, setCRMCompetitorOptions] = useState<string[]>([]);
  const [crmCompetitor, setCRMCompetitor] = useState<string>('');

  // Contracts
  const [showContracts, setShowContracts] = useState(true);

  const [contractsBranchOptions, setContractsBranchOptions] = useState<string[]>([]);
  const [contractsBranch, setContractsBranch] = useState<string>('');

  const [contractsAccountManagerOptions, setContractsAccountManagerOptions] = useState<string[]>([]);
  const [contractsAccountManager, setContractsAccountManager] = useState<string>('');

  const [contractsCustomerOptions, setContractsCustomerOptions] = useState<string[]>([]);
  const [contractsCustomer, setContractsCustomer] = useState<string>('');

  const [contractsMarketSegmentOptions, setContractsMarketSegmentOptions] = useState<string[]>([]);
  const [contractsMarketSegment, setContractsMarketSegment] = useState<string>('');

  // save search
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);
  const [saveSearchName, setSaveSearchName] = useState('');
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [showSaveError, setShowSaveError] = useState(false);

  // updates DALTON
  // parcels
  const [showParcels, setShowParcels] = useState(false);

  // MHI clear view
  const [showheatmap, setshowheatmap] = useState(true)
  const [showdrivetime, setshowdrivetime] = useState(true)
  const [showMHI, setshowMHI] = useState(false)

  const [showHOA, setShowHOA] = useState(false);
  const [HOAcount, setHOAcount] = useState('')

  //// MHI filter
  const [MHI_number, setMHI] = useState<number | ''>('');

  let [CustomerOption, setCustomerOption] = useState<string>('');
  const [showCust, setShowCust] = useState(true);

  // end updates DALTON


  const _tryGetFilterOptions = (layer: __esri.FeatureLayer, field: string, abortSignal: AbortSignal, baseQuery: string = "1=1") => {
    return new Promise<any[]>((resolve, reject) => {
      const where = `${baseQuery} AND ${field} IS NOT NULL`;
      layer.queryFeatureCount({
        where,
        outFields: [field],
        returnDistinctValues: true
      }).then(count => {
        const pageSize = 1000;
        const pages = Math.ceil(count / pageSize);
        const promises = [];
        for (let page = 0; page < pages; page++) {
          const promise = layer.queryFeatures({
            where,
            outFields: [field],
            returnDistinctValues: true,
            orderByFields: [`${field} ASC`],
            returnGeometry: false,
            num: pageSize,
            start: page * pageSize
          })
          promises.push(promise);
        }

        eachAlways(promises).then((responses: __esri.EachAlwaysResult[]) => {
          let allOptions: any[] = [];

          const errorResponse = responses.find(response => response.error);
          if (errorResponse) {
            reject(errorResponse.error);
          } else {
            responses.forEach(response => {
              if (response.value) {
                const featureSet = response.value as __esri.FeatureSet;
                const options = featureSet.features.map(feature => {
                  return feature.attributes[field];
                })
                allOptions = allOptions.concat(options);
              }
            });

            if (abortSignal.aborted) {
              reject("Request aborted");
            } else {
              resolve(allOptions);
            }
          }
        });
      }).catch(() => { reject('Failed to get count') })
    });
  }

  // update from lydia - open only one Accordion at a time
  const applyOneAtATime = () => {

    let acc = document.getElementsByClassName("cds--accordion__item");
    for (let i = 0; i < acc.length; i++) {
      let acci = acc[i] as HTMLElement | null;

      if (acci) {
        acci.onclick = function () {

          let thisAcc = this as HTMLElement | null;

          for (let j = 0; j < acc.length; j++) {
            let accj = acc[j] as HTMLElement | null;

            if (accj && thisAcc) {
              if (accj != thisAcc && acc[j].classList.value === "cds--accordion__item cds--accordion__item--active") {
                setTimeout(function () { acc[j].classList.remove('cds--accordion__item--active'); }, 50)
              } else if (accj == thisAcc && acc[j].classList.value === "cds--accordion__item") {
                setTimeout(function () { acc[j].classList.add('cds--accordion__item--active'); }, 150)
              }
              /*else if (accj == thisAcc && acc[j].classList.value === "cds--form-item--active") {
              console.log("items")
              setTimeout(function(){acc[j].classList.remove('cds--accordion__item--active');}, 150)
            }
              else if (accj == thisAcc) {
                console.log('item')
              setTimeout(function(){acc[j].classList.remove('cds--accordion__item--active');}, 150)
            }*/
            }
          } // end loop
        } // end onclick function
      }
    } // end loop
  } // end function
  // end update from lydia

  const getFilterOptions = useCallback((layer: __esri.FeatureLayer, field: string, abortSignal: AbortSignal, baseQuery: string = "1=1") => {
    return new Promise<any[]>((resolve, reject) => {

      const attemptFetch = (attempt: number) => {
        if (attempt < 3) {
          _tryGetFilterOptions(layer, field, abortSignal, baseQuery).then(options => {
            resolve(options)
          }).catch(() => {
            attemptFetch(attempt + 1);
          })
        } else {
          reject("Max attempts exceeded");
        }
      }
      attemptFetch(0);
    })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    if (filterOptionsInitialized.current) return;
    props.view.current?.when(() => {
      filterOptionsInitialized.current = true;
      const propLayer = props.view.current.map.findLayerById('Property');
      if (propLayer) {
        propertyLayer.current = propLayer as __esri.FeatureLayer;

        // property types
        //getFilterOptions(propertyLayer.current, 'Property_Type_Main', abortController.signal).then((options) => {
        //UPDATES DALTON
        setPropertyTypeOptions(main_type_select)
        //}).catch((error) => {
        //console.log(error);
        //});

        // customer UPDATES FROM NATALIE
        getFilterOptions(propertyLayer.current, 'Customer', abortController.signal).then((options) => {
          setCustomerOptions(options);
          setSavedSearchesLoaded(true)
        }).catch((error) => {
          console.log(error);
        });

        // building status
        getFilterOptions(propertyLayer.current, 'Building_Status', abortController.signal).then((options) => {
          setBuildingStatusOptions(options);
        }).catch((error) => {
          console.log(error);
        });

        // building class
        getFilterOptions(propertyLayer.current, 'Building_Class', abortController.signal).then((options) => {
          setBuildingClassOptions(options);
        }).catch((error) => {
          console.log(error);
        });
      }

      const msLayer = props.view.current.map.findLayerById('MarketSummary');
      if (msLayer) {
        marketSummaryLayer.current = msLayer as __esri.FeatureLayer;
      }

      const crmLayer = props.view.current.map.findLayerById('CRMRecord');
      if (crmLayer) {
        crmRecordLayer.current = crmLayer as __esri.FeatureLayer;

        getFilterOptions(crmRecordLayer.current, "PropertyStatusName", abortController.signal).then((options) => {
          setCRMStatusOptions(options);
        }).catch((error) => {
          console.log(error);
        });

        crmRecordLayer.current.visible = showCRMProspects;
      }

      const contractGroup = props.view.current.map.findLayerById('ContractGroup');
      if (contractGroup) {
        contractGroupLayer.current = contractGroup as __esri.GroupLayer;
        contractGroupLayer.current.visible = showContracts;
      }

      const contractProperty = props.view.current.map.findLayerById('ContractProperty');
      if (contractProperty) {
        contractPropertyLayer.current = contractProperty as __esri.FeatureLayer;
      }

      const contractHeatMap = props.view.current.map.findLayerById('ContractHeatMap');
      if (contractHeatMap) {
        contractHeatMapLayer.current = contractHeatMap as __esri.FeatureLayer;
      }
    });

    return () => {
      abortController.abort();
    }
  }, [props.view, showCRMProspects, showContracts, getFilterOptions]);

  useEffect(() => {
    const abortController = new AbortController();
    props.view.current?.when(() => {
      setLocationLoaded(false)
      // State
      getFilterOptions(propertyLayer.current!, 'State', abortController.signal, props.propertyBaseFilterExpression).then((options) => {
        //console.log(props.propertyBaseFilterExpression)
        setStateOptions(options);
        setLocationLoaded(true)
        //console.log(options)
      }).catch((error) => {
        console.log(error);
      });
    })
  }, [getFilterOptions, props.propertyBaseFilterExpression]);

  useEffect(() => {
    const abortController = new AbortController();
    props.view.current?.when(() => {
      if (Array.from(selectedStateOptions).length > 0) {
        setLocationLoaded(false)
        const criteriaArray = []
        const criteria1 = `State = ${Array.from(selectedStateOptions).map(option => `'${option}'`).join(' or State = ')}`
        const criteria2 = `Zip LIKE ${Array.from(selectedZipOptions).map(option => `'%${option}%'`).join(' or Zip LIKE ')}`
        if (Array.from(selectedStateOptions).length > 0) {
          criteriaArray.push(criteria1);
        }
        if (Array.from(selectedZipOptions).length > 0) {
          criteriaArray.push(criteria2);
        }
        if (props.propertyBaseFilterExpression) {
          criteriaArray.push(props.propertyBaseFilterExpression);
        }
        let baseQuery = criteriaArray.map(term => `(${term})`).join(" AND ");
        // City
        getFilterOptions(propertyLayer.current!, 'City', abortController.signal, baseQuery).then((options) => {
          setCityOptions(options);
          setLocationLoaded(true)
        }).catch((error) => {
          console.log(error);
        });
      }
    })
  }, [getFilterOptions, props.propertyBaseFilterExpression, selectedStateOptions, selectedZipOptions]);

  useEffect(() => {
    const abortController = new AbortController();
    props.view.current?.when(() => {
      if (Array.from(selectedStateOptions).length > 0) {
        setLocationLoaded(false)
        const criteriaArray = []
        const criteria1 = `State = ${Array.from(selectedStateOptions).map(option => `'${option}'`).join(' or State = ')}`
        const criteria2 = `City = ${Array.from(selectedCityOptions).map(option => `'${option}'`).join(' or City = ')}`
        if (Array.from(selectedStateOptions).length > 0) {
          criteriaArray.push(criteria1);
        }
        if (Array.from(selectedCityOptions).length > 0) {
          criteriaArray.push(criteria2);
        }
        if (props.propertyBaseFilterExpression) {
          criteriaArray.push(props.propertyBaseFilterExpression);
        }
        let baseQuery = criteriaArray.map(term => `(${term})`).join(" AND ");
        console.log(baseQuery)
        // Zip
        getFilterOptions(propertyLayer.current!, 'Zip', abortController.signal, baseQuery).then((options) => {
          // Split strings with "-" and filter by length
          const modifiedArray = options.map(item => item.split("-")[0]).filter(item => item.length === 5);

          function removewithfilter(arr: any) {
            let outputArray = arr.filter(function (v: any, i: any, self: string | any[]) {

              // It returns the index of the first
              // instance of each value
              return i == self.indexOf(v);
            });

            return outputArray;
          }
          const filteredArray = removewithfilter(modifiedArray);
          setzipOptions(filteredArray);
          setLocationLoaded(true)

        }).catch((error) => {
          console.log(error);
        });
      }
    })
  }, [selectedStateOptions, selectedCityOptions, props.propertyBaseFilterExpression, getFilterOptions]);

  useEffect(() => {
    const abortController = new AbortController();
    props.view.current?.when(() => {
      if (props.propertyBaseFilterExpression !== '') {
        getFilterOptions(propertyLayer.current!, 'Closest_Branch', abortController.signal, props.propertyBaseFilterExpression).then((options) => {
          setBranchOptions(options);
        }).catch((error) => {
          console.log(error);
        });
      }

      return () => {
        abortController.abort();
      }
    })
  }, [propertyLayer, props.propertyBaseFilterExpression, getFilterOptions]);

  // MHI effect

  //useEffect(() => {

  // Updates DALTON

  const toggleCust = () => {
    setShowCust(!showCust);
    ;
  }

  const MHIeffect = () => {
    //console.log("in");
    const MHItime = props.view.current.map.findLayerById('MapImageLayer') as __esri.MapImageLayer;

    const zips = MHItime.findSublayerById(9);
    //let MHI_number = 0;
    //const ZIPvalue = MHI_number;
    //let ZIPvalue= 0;
    //console.log(MHItime);
    //console.log(zips);
    zips.definitionExpression = "MEDHINC_CY >= " + MHI_number;
  };

  if (MHI_number == null) {
    setMHI(0)
  }

  if (MHI_number !== '' && MHI_number !== null) {
    MHIeffect();
  }

  const zipEffect = () => {
    const propertyLayer = props.view.current.map.findLayerById('Property') as __esri.FeatureLayer

    let terms: any = []

    let definitionExpression = terms.map((term: any) => `(${term})`).join(" AND ");

    setCityStateZipExpression('')
  };

  // End updates Dalton

  useEffect(() => {
    const abortController = new AbortController();
    crmRecordLayer.current?.when(() => {
      if (props.crmRecordBaseFilterExpression !== '') {
        getFilterOptions(crmRecordLayer.current!, "Competitor", abortController.signal, props.crmRecordBaseFilterExpression).then((options) => {
          setCRMCompetitorOptions(options);
        }).catch((error) => {
          console.log(error);
        });
      }
      return () => {
        abortController.abort();
      }
    })
  }, [crmRecordLayer, props.crmRecordBaseFilterExpression, getFilterOptions]);

  useEffect(() => {
    const abortController = new AbortController();
    contractPropertyLayer.current?.when(() => {
      if (props.contractBaseFilterExpression !== '') {
        getFilterOptions(contractPropertyLayer.current!, 'BranchName', abortController.signal, props.contractBaseFilterExpression).then((options) => {
          setContractsBranchOptions(options);
        }).catch((error) => {
          console.log(error);
        });

        getFilterOptions(contractPropertyLayer.current!, 'AccountOwner', abortController.signal, props.contractBaseFilterExpression).then((options) => {
          setContractsAccountManagerOptions(options);
        }).catch((error) => {
          console.log(error);
        });

        getFilterOptions(contractPropertyLayer.current!, 'CompanyName', abortController.signal, props.contractBaseFilterExpression).then((options) => {
          setContractsCustomerOptions(options);
        }).catch((error) => {
          console.log(error);
        });

        getFilterOptions(contractPropertyLayer.current!, 'IndustryName', abortController.signal, props.contractBaseFilterExpression).then((options) => {
          setContractsMarketSegmentOptions(options);
        }).catch((error) => {
          console.log(error);
        });
      }
      return () => {
        abortController.abort();
      }
    })
  }, [contractPropertyLayer, props.contractBaseFilterExpression, getFilterOptions]);

  const applyPropertyLayerFilters = () => {
    let terms = [];
    if (props.propertyBaseFilterExpression && props.propertyBaseFilterExpression !== '1=1') {
      terms.push(props.propertyBaseFilterExpression);
    }
    if (propertyTypeExpression) {
      terms.push(propertyTypeExpression);
    }
    if (lotSizeExpression) {
      terms.push(lotSizeExpression);
    }
    if (contactExpression) {
      terms.push(contactExpression);
    }

    const morePropertyExpressions = Object.keys(moreFilterExpressions.property)
      .map(key => (moreFilterExpressions.property as any)[key])
      .filter(term => term);

    if (morePropertyExpressions.length > 0) {
      terms = terms.concat(morePropertyExpressions);
    }

    const definitionExpression = terms.map(term => `(${term})`).join(" AND ");

    propertyLayer.current?.when(() => {
      propertyLayer.current!.definitionExpression = definitionExpression;
    })
    marketSummaryLayer.current?.when(() => {
      marketSummaryLayer.current!.definitionExpression = definitionExpression;
    })
    props.setPropertyDefinitionExpression(definitionExpression);
  }
  useEffect(applyPropertyLayerFilters,
    [
      props,
      propertyTypeExpression,
      lotSizeExpression,
      contactExpression,
      moreFilterExpressions.property,
    ]
  );

  /*useEffect(() => {
    const checkCurrentPropertyLayer = () => {
      let terms = [];
      if (props.propertyBaseFilterExpression && props.propertyBaseFilterExpression !== '1=1') {
        terms.push(props.propertyBaseFilterExpression);
      }
      if (propertyTypeExpression) {
        terms.push(propertyTypeExpression);
      }
      if (lotSizeExpression) {
        terms.push(lotSizeExpression);
      }
      if (contactExpression) {
        terms.push(contactExpression);
      }

      const morePropertyExpressions = Object.keys(moreFilterExpressions.property)
        .map(key => (moreFilterExpressions.property as any)[key])
        .filter(term => term);

      if (morePropertyExpressions.length > 0) {
        terms = terms.concat(morePropertyExpressions);
      }

      const definitionExpression = terms.map(term => `(${term})`).join(" AND ");
      if (propertyLayer.current) {
        propertyLayer.current.definitionExpression = definitionExpression;
        clearInterval(interval); // Stop checking once the layer is current
      }
      if (marketSummaryLayer.current) {
        marketSummaryLayer.current.definitionExpression = definitionExpression;
      }
      props.setPropertyDefinitionExpression(definitionExpression);
    };
    const interval = setInterval(checkCurrentPropertyLayer, 500); // Call the function every 1000 milliseconds (1 second)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  },
    [
      props,
      propertyTypeExpression,
      lotSizeExpression,
      contactExpression,
      moreFilterExpressions.property,
    ]
  );*/

  const applyCRMRecordLayerFilters = () => {
    let terms = [];
    if (props.crmRecordBaseFilterExpression && props.crmRecordBaseFilterExpression !== '1=1') {
      terms.push(props.crmRecordBaseFilterExpression);
    }

    const filters = Object.keys(moreFilterExpressions.crmProspects)
      .map(key => (moreFilterExpressions.crmProspects as any)[key])
      .filter(term => term);

    if (filters.length > 0) {
      terms = terms.concat(filters);
    }
    const definitionExpression = terms.map(term => `(${term})`).join(" AND ");

    if (crmRecordLayer.current) {
      crmRecordLayer.current.definitionExpression = definitionExpression;
    }
  }
  useEffect(applyCRMRecordLayerFilters, [
    props.crmRecordBaseFilterExpression,
    moreFilterExpressions.crmProspects
  ]);

  const applyContractLayerFilters = () => {
    let terms = [];
    if (props.contractBaseFilterExpression && props.contractBaseFilterExpression !== '1=1') {
      terms.push(props.contractBaseFilterExpression);
    }

    const filters = Object.keys(moreFilterExpressions.activeContracts)
      .map(key => (moreFilterExpressions.activeContracts as any)[key])
      .filter(term => term);

    if (filters.length > 0) {
      terms = terms.concat(filters);
    }
    const definitionExpression = terms.map(term => `(${term})`).join(" AND ");

    if (contractPropertyLayer.current) {
      contractPropertyLayer.current.definitionExpression = definitionExpression;
    }
    if (contractHeatMapLayer.current) {
      contractHeatMapLayer.current.definitionExpression = definitionExpression;
    }
  }
  useEffect(applyContractLayerFilters, [
    props.contractBaseFilterExpression,
    moreFilterExpressions.activeContracts
  ]);

  /*const applyMHILayerFilters = () => {
    const MHItime = props.view.current.map.findLayerById('MapImageLayer') as __esri.MapImageLayer;
    const zips = MHItime.findSublayerById(9);
    let terms = [];
    if (props.MHIBaseFilterExpression && props.MHIBaseFilterExpression !== '1=1') {
      terms.push(props.MHIBaseFilterExpression);
    }

    const filters = Object.keys(moreFilterExpressions.MHI)
      .map(key => (moreFilterExpressions.MHI as any)[key])
      .filter(term => term);

    if (filters.length > 0) {
      terms = terms.concat(filters);
    }
    const definitionExpression = "MEDHINC_CY >= " + MHI_number;;

    if (zips) {
      zips.definitionExpression = definitionExpression;
    }
  }
  useEffect(applyMHILayerFilters, [
    props.MHIBaseFilterExpression,
    moreFilterExpressions.MHI
  ]); */


  // Property Type
  const onPropertyTypeCheckboxChange = (evt: any) => {
    const types = new Set(selectedPropertyTypes);
    if (evt.target.checked) {
      types.add(evt.target.value);
    } else {
      types.delete(evt.target.value);
    }
    setSelectedPropertyTypes(types);
  }

  // updates from lydia
  // Secondary Property Type
  const onSecondaryPropertyTypeCheckboxChange = (propertyType: PropertyType) => (evt: any) => {
    const secondaryTypes = { ...secondarySelectedPropertyTypes };
    if (evt.target.checked) {
      secondaryTypes[propertyType] = addSecondary(secondaryTypes[propertyType], evt.target.value);
    } else {
      secondaryTypes[propertyType] = deleteSecondary(secondaryTypes[propertyType], evt.target.value);
    }
    setSecondarySelectedPropertyTypes(secondaryTypes);
  }
  // end updates from lydia

  const resetPropertyTypeFilters = () => {
    setSelectedPropertyTypes(new Set());
    setPropertyTypeExpression('');

    // updates from lydia
    const secondaryTypes = { ...secondarySelectedPropertyTypes };
    const keys = Object.keys(secondaryTypes);
    keys.forEach(key => { secondaryTypes[key as PropertyType] = [] });
    setSecondarySelectedPropertyTypes(secondaryTypes);
    // end updates from lydia
  }

  const applyPropertyTypeFilters = () => {
    console.log('applyPropertyTypeFilters', selectedPropertyTypes)
    const expression = buildPropertyTypeExpression(Array.from(selectedPropertyTypes), secondarySelectedPropertyTypes); // update from lydia
    setPropertyTypeExpression(expression);
  }

  // updates from lydia
  const buildPropertyTypeExpression = useCallback((types: string[], secondaryTypes: { [key: string]: string[] }) => {
    let expression = '';
    const terms = [];

    if (types.includes('Flex')) {
      if (secondaryTypes["Flex"][0]) {
        const csv = secondaryTypes["Flex"].map(x => `'${x}'`).join(',');
        terms.push(`Property_Type_Main LIKE 'Flex' AND (Secondary_Type IN (${csv}))`);
      } else {
        terms.push(`Property_Type_Main LIKE 'Flex'`);
      }
    }
    if (types.includes('Health Care')) {
      if (secondaryTypes["Health Care"][0]) {
        const csv = secondaryTypes["Health Care"].map(x => `'${x}'`).join(',');
        terms.push(`Property_Type_Main LIKE 'Health Care' AND Secondary_Type IN (${csv})`);
      } else {
        terms.push(`Property_Type_Main LIKE 'Health Care'`);
      }
    }
    if (types.includes('Hospitality')) {
      if (secondaryTypes["Hospitality"][0]) {
        const csv = secondaryTypes["Hospitality"].map(x => `'${x}'`).join(',');
        terms.push(`Property_Type_Main LIKE 'Hospitality' AND Secondary_Type IN (${csv})`);
      } else {
        terms.push(`Property_Type_Main LIKE 'Hospitality'`);
      }
    }
    if (types.includes('Industrial')) {
      if (secondaryTypes["Industrial"][0]) {
        const csv = secondaryTypes["Industrial"].map(x => `'${x}'`).join(',');
        terms.push(`Property_Type_Main LIKE 'Industrial' AND Secondary_Type IN (${csv})`);
      } else {
        terms.push(`Property_Type_Main LIKE 'Industrial'`);
      }
    }
    if (types.includes('Multi-Family')) {
      if (secondaryTypes["Multi-Family"][0]) {
        const csv = secondaryTypes["Multi-Family"].map(x => `'${x}'`).join(',');
        terms.push(`Property_Type_Main LIKE 'Multi-Family' AND Secondary_Type IN (${csv})`);
      } else {
        terms.push(`Property_Type_Main LIKE 'Multi-Family'`);
      }
    }
    if (types.includes('Office')) {
      if (secondaryTypes["Office"][0]) {
        const csv = secondaryTypes["Office"].map(x => `'${x}'`).join(',');
        terms.push(`Property_Type_Main LIKE 'Office' AND Secondary_Type IN (${csv})`);
      } else {
        terms.push(`Property_Type_Main LIKE 'Office'`);
      }
    }
    if (types.includes('Retail')) {
      if (secondaryTypes["Retail"][0]) {
        const csv = secondaryTypes["Retail"].map(x => `'${x}'`).join(',');
        terms.push(`Property_Type_Main LIKE 'Retail' AND Secondary_Type IN (${csv})`);
      } else {
        terms.push(`Property_Type_Main LIKE 'Retail'`);
      }
    }
    if (types.includes('Specialty')) {
      if (secondaryTypes["Specialty"][0]) {
        const csv = secondaryTypes["Specialty"].map(x => `'${x}'`).join(',');
        terms.push(`Property_Type_Main LIKE 'Specialty' AND Secondary_Type IN (${csv})`);
      } else {
        terms.push(`Property_Type_Main LIKE 'Specialty'`);
      }
    }
    if (types.includes('Sports & Entertainment')) {
      if (secondaryTypes["Sports & Entertainment"][0]) {
        const csv = secondaryTypes["Sports & Entertainment"].map(x => `'${x}'`).join(',');
        terms.push(`Property_Type_Main LIKE 'Sports & Entertainment' AND Secondary_Type IN (${csv})`);
      } else {
        terms.push(`Property_Type_Main LIKE 'Sports & Entertainment'`);
      }
    }
    if (types.includes('Student')) {
      if (secondaryTypes["Student"][0]) {
        const csv = secondaryTypes["Student"].map(x => `'${x} (Student)'`).join(',');
        terms.push(`Property_Type_Main LIKE 'Student' AND Secondary_Type IN (${csv})`);
      } else {
        terms.push(`Property_Type_Main LIKE 'Student'`);
      }
    }

    expression = terms.map(term => `(${term})`).join(" OR ");
    return expression;
  }, []);
  // end updates from lydia

  // Lot Size
  const onLotSizeChange = (evt: any) => {
    setLotSizeMin(evt.target.value);
  }

  const resetLotSizeFilter = () => {
    setLotSizeMin('');
    setLotSizeExpression('');
  }

  const applyLotSizeFilter = () => {
    const expression = buildLotSizeExpression(lotSizeMin);
    setLotSizeExpression(expression)
  }

  const buildLotSizeExpression = useCallback((lotSize: number | '') => {
    let expression = '';
    if (lotSize) {
      expression = `Land_Area__AC_ >= ${lotSize}`;
    }
    return expression;
  }, [])

  // Contact Info
  const onContactCheckboxChange = (evt: any) => {
    const types = new Set(selectedContactTypes);
    if (evt.target.checked) {
      types.add(evt.target.value);
    } else {
      types.delete(evt.target.value);
    }
    setSelectedContactTypes(types);
  }

  const onContactSearchTermChange = (contactType: ContactType) => (evt: any) => {
    const searchTerms = { ...contactSearchTerms };
    searchTerms[contactType] = evt.target.value;
    setContactSearchTerms(searchTerms);
  }

  const resetContactFilters = () => {
    setSelectedContactTypes(new Set());
    const searchTerms = { ...contactSearchTerms };
    const keys = Object.keys(searchTerms);
    keys.forEach(key => { searchTerms[key as ContactType] = '' });
    setContactSearchTerms(searchTerms);
    setContactExpression('');
  }

  const applyContactFilters = () => {
    const expression = buildContactExpression(selectedContactTypes, contactSearchTerms);
    setContactExpression(expression);
  }

  const buildContactExpression = useCallback((types: Set<string>, searchTerms: { [key: string]: string }) => {

    const terms = [];
    if (types.has('property-manager')) {
      if (searchTerms["property-manager"]) {
        const searchTerm = searchTerms["property-manager"];
        terms.push(`Property_Manager_Name LIKE '%${searchTerm}%' OR Property_Manager_Contact LIKE '%${searchTerm}%'`);
      } else {
        terms.push('Property_Manager_Name IS NOT NULL OR Property_Manager_Contact IS NOT NULL');
      }
    }
    if (types.has('leasing-company')) {
      if (searchTerms["leasing-company"]) {
        const searchTerm = searchTerms["leasing-company"];
        terms.push(`Leasing_Company_Name LIKE '%${searchTerm}%' OR Leasing_Company_Contact LIKE '%${searchTerm}%'`);
      } else {
        terms.push('Leasing_Company_Name IS NOT NULL OR Leasing_Company_Contact IS NOT NULL');
      }
    }
    if (types.has('property-owner')) {
      if (searchTerms["property-owner"]) {
        const searchTerm = searchTerms["property-owner"];
        terms.push(`Owner_Name LIKE '%${searchTerm}%' OR Owner_Contact LIKE '%${searchTerm}%'`);
      } else {
        terms.push('Owner_Name IS NOT NULL OR Owner_Contact IS NOT NULL');
      }
    }

    return terms.map(term => `(${term})`).join(" AND ");
  }, [])

  // More Filters
  const resetMoreFilters = () => {
    setShowSavePropertiesOnly(false);
    setSelectCustomerOptions(new Set()); //UPDATES FROM NATALIE
    setSelectBuildingStatusOptions(new Set());
    setBuildingSizeMin('');
    setSelectBuildingClassOptions(new Set());
    setParkingMin('');
    setAmenitiesSearchText('');
    setClosestBranch('');
    setDriveTimeMax(0);
    setSelectZipOptions(new Set());
    setSelectCityOptions(new Set());
    setSelectStateOptions(new Set());

    setSelectedCRMStatusOptions(new Set());
    setCRMCompetitor('');

    setContractsBranch('');
    setContractsAccountManager('');
    setContractsCustomer('');
    setContractsMarketSegment('');
    // updates DALTON
    setMHI(0);
    setshowMHI(false);
    setShowParcels(false);
    setshowdrivetime(true);
    setshowheatmap(true);
    setShowHOA(false);
    // end updates DALTON

    setMoreFilterExpressions({
      property: {
        savedProperties: '',
        customer: '', //UPDATES FROM NATALIE
        status: '',
        buildingSize: '',
        class: '',
        parking: '',
        amenities: '',
        closestBranch: '',
        driveTime: '',
        zip: '',
        city: '',
        state: ''
      },
      crmProspects: {
        status: '',
        competitor: ''
      },
      activeContracts: {
        branch: '',
        accountManager: '',
        customer: '',
        marketSegment: ''
      },
      MHI: { // updates DALTON
        MEDHINC_CY: '',
      }
    });

  }

  const buildMoreFilterExpressions = useCallback((
    _showSavedPropertiesOnly?: boolean,
    _selectedCustomerOptions?: Set<string>, //UPDATES FROM NATALIE
    _selectedBuildingStatusOptions?: Set<string>,
    _buildingSizeMin?: number | '',
    _selectedBuildingClassOptions?: Set<string>,
    _parkingMin?: number | '',
    _amenitiesSearchText?: string,
    _closestBranch?: string,
    _driveTimeMax?: number,
    _selectedCRMStatusOptions?: Set<string>,
    _crmCompetitor?: string,
    _contractsBranch?: string,
    _contractsAccountManager?: string,
    _contractsCustomer?: string,
    _contractsMarketSegment?: string,
    _MHI_number?: number | '', // updates DALTON
    _showMHI?: boolean, // updates DALTON
    _showdrivetime?: boolean, // updates DALTON
    _showheatmap?: boolean, // updates DALTON
    _selectedZipOptions?: Set<string>,
    _selectedCityOptions?: Set<string>,
    _selectedStateOptions?: Set<string>,
    _showHOA?: boolean,
  ) => {
    const expressions = {
      property: {
        savedProperties: '',
        customer: '', //UPDATES FROM NATALIE
        status: '',
        buildingSize: '',
        class: '',
        parking: '',
        amenities: '',
        closestBranch: '',
        driveTime: '',
        zip: '',
        city: '',
        state: '',
      },
      crmProspects: {
        status: '',
        competitor: ''
      },
      activeContracts: {
        branch: '',
        accountManager: '',
        customer: '',
        marketSegment: ''
      },
      MHI: { // updates DALTON
        MEDHINC_CY: ''
      }
    };

    //// Property
    // saved properties
    if (_showSavedPropertiesOnly && props.favoriteProperties && props.favoriteProperties.size > 0) {
      expressions.property.savedProperties = `PropertyID IN (${Array.from(props.favoriteProperties).join(',')})`;
    } else {
      expressions.property.savedProperties = '';
    }

    // customer UPDATES FROM NATALIE
    if (_selectedCustomerOptions && _selectedCustomerOptions.size > 0) {
      const csv = Array.from(_selectedCustomerOptions).map(x => `'${x}'`).join(',');
      expressions.property.customer = `Customer IN (${csv})`;
    } else {
      expressions.property.customer = '';
    }

    // status
    if (_selectedBuildingStatusOptions && _selectedBuildingStatusOptions.size > 0) {
      const csv = Array.from(_selectedBuildingStatusOptions).map(x => `'${x}'`).join(',');
      expressions.property.status = `Building_Status IN (${csv})`;
    } else {
      expressions.property.status = '';
    }

    // building size
    if (_buildingSizeMin) {
      expressions.property.buildingSize = `RBA >= ${_buildingSizeMin}`;
    } else {
      expressions.property.buildingSize = '';
    }

    // class
    if (_selectedBuildingClassOptions && _selectedBuildingClassOptions.size > 0) {
      const csv = Array.from(_selectedBuildingClassOptions).map(x => `'${x}'`).join(',');
      expressions.property.class = `Building_Class IN (${csv})`;
    } else {
      expressions.property.class = '';
    }

    // parking
    if (_parkingMin) {
      expressions.property.parking = `Number_Of_Parking_Spaces >= ${_parkingMin}`;
    } else {
      expressions.property.parking = '';
    }

    // amenities
    if (_amenitiesSearchText) {
      expressions.property.amenities = `Amenities LIKE '%${_amenitiesSearchText}%'`;
    } else {
      expressions.property.amenities = '';
    }

    // closest branch
    if (_closestBranch) {
      console.log("close")
      expressions.property.closestBranch = `Closest_Branch = '${_closestBranch}'`;
    } else {
      expressions.property.closestBranch = '';
    }

    // drive time
    if (_driveTimeMax) {
      expressions.property.driveTime = `ToBreak <= ${_driveTimeMax}`;
    } else {
      expressions.property.driveTime = ''
    }

    // Zip Code
    if (_selectedZipOptions) {
      const zipConditions: string[] = [];
      let ARRAY = Array.from(_selectedZipOptions)
      ARRAY.forEach(zip => {
        zipConditions.push(`Zip LIKE '%${zip}%'`);
        //zipConditions.push(`Zip = '${zip}'`);
      });
      expressions.property.zip = zipConditions.join(' OR ');
      //console.log(expressions.property.zip)
    } else {
      expressions.property.zip = '';
    }

    // City
    if (_selectedCityOptions) {
      const cityConditions: string[] = [];
      let ARRAY = Array.from(_selectedCityOptions)
      ARRAY.forEach(city => {
        cityConditions.push(`City = '${city}'`);
        //zipConditions.push(`Zip = '${zip}'`);
      });
      expressions.property.city = cityConditions.join(' OR ');
      //console.log(expressions.property.city)
    } else {
      expressions.property.city = '';
    }

    // State
    if (_selectedStateOptions) {
      const stateConditions: string[] = [];
      let ARRAY = Array.from(_selectedStateOptions)
      ARRAY.forEach(state => {
        stateConditions.push(`State = '${state}'`);
        //zipConditions.push(`Zip = '${zip}'`);
      });
      expressions.property.state = stateConditions.join(' OR ');
      //console.log(expressions.property.state)
    } else {
      expressions.property.state = '';
    }

    //// CRM
    // status
    if (_selectedCRMStatusOptions && _selectedCRMStatusOptions.size > 0) {
      const csv = Array.from(_selectedCRMStatusOptions).map(x => `'${x}'`).join(',');
      expressions.crmProspects.status = `PropertyStatusName IN (${csv})`
    } else {
      expressions.crmProspects.status = '';
    }

    if (_crmCompetitor) {
      expressions.crmProspects.competitor = `Competitor = '${_crmCompetitor}'`
    } else {
      expressions.crmProspects.competitor = '';
    }

    //// Active contracts
    // branch
    if (_contractsBranch) {
      expressions.activeContracts.branch = `BranchName = '${_contractsBranch}'`
    } else {
      expressions.activeContracts.branch = '';
    }

    // account manager
    if (_contractsAccountManager) {
      expressions.activeContracts.accountManager = `AccountOwner = '${_contractsAccountManager}'`
    } else {
      expressions.activeContracts.accountManager = '';
    }

    // customer
    if (_contractsCustomer) {
      expressions.activeContracts.customer = `CompanyName = '${_contractsCustomer}'`
    } else {
      expressions.activeContracts.customer = '';
    }

    // market segment
    if (_contractsMarketSegment) {
      expressions.activeContracts.marketSegment = `IndustryName = '${_contractsMarketSegment}'`
    } else {
      expressions.activeContracts.marketSegment = '';
    }

    /* //// MHI
     // MHInumber
     if (MHI_number) {
       expressions.MHI.MEDHINC_CY = `MEDHINC_CY > '${MHI_number}'`
     } else {
       expressions.MHI.MEDHINC_CY = '';
     }*/

    return expressions;
  }, [props.favoriteProperties])

  const applyMoreFilters = useCallback(() => {

    // lydia
    MHIeffect();

    const expressions = buildMoreFilterExpressions(
      showSavedPropertiesOnly,
      selectedCustomerOptions, //UPDATES FROM NATALIE
      selectedBuildingStatusOptions,
      buildingSizeMin,
      selectedBuildingClassOptions,
      parkingMin,
      amenitiesSearchText,
      closestBranch,
      driveTimeMax,
      selectedCRMStatusOptions,
      crmCompetitor,
      contractsBranch,
      contractsAccountManager,
      contractsCustomer,
      contractsMarketSegment,
      MHI_number, // updates DALTON
      showMHI, // updates DALTON
      showdrivetime, // updates DALTON
      showheatmap, // updates DALTON
      selectedZipOptions,
      selectedCityOptions,
      selectedStateOptions,
    );
    setMoreFilterExpressions(expressions);
  }, [
    showSavedPropertiesOnly,
    selectedCustomerOptions, //UPDATES FROM NATALIE
    selectedBuildingStatusOptions,
    buildingSizeMin,
    selectedBuildingClassOptions,
    parkingMin,
    amenitiesSearchText,
    closestBranch,
    driveTimeMax,
    selectedCRMStatusOptions,
    crmCompetitor,
    contractsBranch,
    contractsAccountManager,
    contractsCustomer,
    contractsMarketSegment,
    MHI_number, // updates DALTON
    showMHI, // updates DALTON
    showdrivetime, // updates DALTON
    showheatmap, // updates DALTON
    selectedZipOptions,
    selectedCityOptions,
    selectedStateOptions,
    buildMoreFilterExpressions
  ])

  ////
  const toggleShowSavedPropertiesOnly = () => {
    setShowSavePropertiesOnly(!showSavedPropertiesOnly);
  }

  //// customer UPDATES FROM NATALIE
  const onCustomerCheckboxChange = (evt: any) => {
    const options = new Set(selectedCustomerOptions);
    if (evt.target.checked) {
      options.add(evt.target.value);
      setCustomerOption("YES")
    } else {
      options.delete(evt.target.value);
    }
    setSelectCustomerOptions(options);
  }

  const clearCustomerFilters = () => {
    setSelectCustomerOptions(new Set());

    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.property.customer = '';
    setMoreFilterExpressions(expressions);
  }


  //// Status
  const onBuildingStatusCheckboxChange = (evt: any) => {
    const options = new Set(selectedBuildingStatusOptions);
    if (evt.target.checked) {
      options.add(evt.target.value);
    } else {
      options.delete(evt.target.value);
    }
    setSelectBuildingStatusOptions(options);
  }

  const clearBuildingStatusFilters = () => {
    setSelectBuildingStatusOptions(new Set());

    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.property.status = '';
    setMoreFilterExpressions(expressions);
  }

  //// Building Size
  const clearBuildingSizeFilter = () => {
    setBuildingSizeMin('');

    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.property.buildingSize = '';
    setMoreFilterExpressions(expressions);
  }

  // updates DALTON

  //// MHI filter
  const clearMHI = () => {
    setMHI(0);
    //MHIeffect();

    //end updates DALTON

    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.MHI.MEDHINC_CY = '';
    setMoreFilterExpressions(expressions);
  }

  //// Class
  const onBuildingClassCheckboxChange = (evt: any) => {
    const options = new Set(selectedBuildingClassOptions);
    if (evt.target.checked) {
      options.add(evt.target.value);
    } else {
      options.delete(evt.target.value);
    }
    setSelectBuildingClassOptions(options);
  }

  const clearBuildingClassFilters = () => {
    setSelectBuildingClassOptions(new Set());
    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.property.class = '';
    setMoreFilterExpressions(expressions);
  }

  //// Parking
  const clearParkingFilter = () => {
    setParkingMin('');

    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.property.parking = '';
    setMoreFilterExpressions(expressions);
  }


  //// Amenities
  const clearAmenitiesFilter = () => {
    setAmenitiesSearchText('');

    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.property.amenities = '';
    setMoreFilterExpressions(expressions);
  }

  //// Drive time
  const clearClosestBranchFilter = () => {
    setClosestBranch('');
    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.property.closestBranch = '';
    setMoreFilterExpressions(expressions);
  }

  const clearDriveTimeMaxFilter = () => {
    setDriveTimeMax(0);
    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.property.driveTime = '';
    setMoreFilterExpressions(expressions);
  }

  //// CRM prospects
  const toggleCRMPospectsVisibility = () => {
    setShowCRMProspects(!showCRMProspects);
  }

  useEffect(() => {
    if (crmRecordLayer.current) {
      crmRecordLayer.current.visible = showCRMProspects;
    }
  }, [showCRMProspects]);

  const clearCRMStatusFilters = () => {
    setSelectedCRMStatusOptions(new Set());
    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.crmProspects.status = '';
    setMoreFilterExpressions(expressions);
  }

  const clearCRMCompetitorFilter = () => {
    setCRMCompetitor('');
    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.crmProspects.competitor = '';
    setMoreFilterExpressions(expressions);
  }

  const onCRMStatusCheckboxChange = (evt: any) => {
    const options = new Set(selectedCRMStatusOptions);
    if (evt.target.checked) {
      options.add(evt.target.value);
    } else {
      options.delete(evt.target.value);
    }
    setSelectedCRMStatusOptions(options);
  }

  //// Active contracts
  const toggleContractsVisibility = () => {
    setShowContracts(!showContracts);
  }

  useEffect(() => {
    if (contractGroupLayer.current) {
      contractGroupLayer.current.visible = showContracts;
    }
  }, [showContracts]);

  const clearContractsBranchFilter = () => {
    setContractsBranch('');
    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.activeContracts.branch = '';
    setMoreFilterExpressions(expressions);
  }


  const clearContractsAccountManagerFilter = () => {
    setContractsAccountManager('');
    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.activeContracts.accountManager = '';
    setMoreFilterExpressions(expressions);
  }

  const clearContractsCustomerFilter = () => {
    setContractsCustomer('');
    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.activeContracts.customer = '';
    setMoreFilterExpressions(expressions);
  }

  const clearContractsMarketSegmentFilter = () => {
    setContractsMarketSegment('');
    const expressions = {
      property: { ...moreFilterExpressions.property },
      crmProspects: { ...moreFilterExpressions.crmProspects },
      activeContracts: { ...moreFilterExpressions.activeContracts },
      MHI: { ...moreFilterExpressions.MHI }
    };
    expressions.activeContracts.marketSegment = '';
    setMoreFilterExpressions(expressions);
  }

  const clearLocationFilter = () => {
    setSelectStateOptions(new Set())
    setSelectZipOptions(new Set())
    setSelectCityOptions(new Set())
  }

  const resetAll = () => {
    resetPropertyTypeFilters();
    resetLotSizeFilter();
    resetContactFilters();
    resetMoreFilters();

  }

  const openSaveSearchModal = () => {
    setSaveSearchName('');
    setShowSaveSearchModal(true);
  }

  const closeSaveSearchModal = () => {
    setShowSaveSearchModal(false);
  }

  const saveSearch = () => {
    console.log("save", saveSearchName);

    if (props.user?.username && saveSearchName && props.savedSessionsUrl) { }
    // create json object

    const filters: SessionFilters = {
      propertyTypes: Array.from(selectedPropertyTypes),
      lotSizeMin,
      secondarySelectedPropertyTypes: secondarySelectedPropertyTypes, // updates from lydia
      contactTypes: Array.from(selectedContactTypes),
      contactSearchTerms: contactSearchTerms,
      showSavedPropertiesOnly,
      customer: Array.from(selectedCustomerOptions), //UPDATES FROM NATALIE
      buildingStatus: Array.from(selectedBuildingStatusOptions),
      buildingSizeMin,
      buildingClass: Array.from(selectedBuildingClassOptions),
      parkingMin,
      amenitiesSearchText,
      closestBranch,
      driveTimeMax,
      showCRMProspects,
      crmStatus: Array.from(selectedCRMStatusOptions),
      crmCompetitor,
      showContracts,
      contractsBranch,
      contractsAccountManager,
      contractsCustomer,
      contractsMarketSegment,
      MHI_number, // updates DALTON
      showMHI, // updates DALTON
      showdrivetime, // updates DALTON
      showheatmap, // updates DALTON
      selectedZips: Array.from(selectedZipOptions),
      selectedCities: Array.from(selectedCityOptions),
      selectedStates: Array.from(selectedStateOptions),
      showHOA
    }

    const session = {
      filters,
      mapExtent: props.view.current.extent,
      filterByMapExtent: props.filterByMapExtent,
      boundary: props.boundary
    };
    const sessionJson = JSON.stringify(session);
    var sessionsTable = new FeatureLayer({ url: props.savedSessionsUrl });
    sessionsTable.applyEdits({
      addFeatures: [new Graphic({
        attributes: {
          user_id: props.user?.username,
          session_title: saveSearchName,
          session_json: sessionJson
        }
      })]
    }).then(response => {
      console.log(response);
      if (response.addFeatureResults?.length > 0) {
        const result = response.addFeatureResults[0];
        if (result.error) {
          setShowSaveError(true);
        }
        if (result.objectId) {
          setShowSaveSuccess(true);
        }
      }
    }).catch(reason => {
      setShowSaveError(true);
    });
    closeSaveSearchModal();
  }

  useEffect(() => {

    const buildAndSetFilterExpressions = (filters: SessionFilters | undefined) => {
      if (!filters) return;

      if (filters.propertyTypes) {
        setSelectedPropertyTypes(new Set(filters.propertyTypes));
        const expression = buildPropertyTypeExpression(filters.propertyTypes, filters.secondarySelectedPropertyTypes as any); // update from lydia
        setPropertyTypeExpression(expression);
      }

      // updates from lydia
      if (filters.secondarySelectedPropertyTypes !== undefined) {
        setSecondarySelectedPropertyTypes(filters.secondarySelectedPropertyTypes);
      }
      // end updates from lydia

      if (filters.lotSizeMin !== undefined) {
        setLotSizeMin(filters.lotSizeMin);
        const expression = buildLotSizeExpression(filters.lotSizeMin);
        setLotSizeExpression(expression);
      }

      if (filters.contactTypes !== undefined) {
        setSelectedContactTypes(new Set(filters.contactTypes));
        const expression = buildContactExpression(new Set(filters.contactTypes), filters.contactSearchTerms as any);
        setContactExpression(expression);
      }

      if (filters.contactSearchTerms !== undefined) {
        setContactSearchTerms(filters.contactSearchTerms);
      }

      if (filters.showSavedPropertiesOnly !== undefined) {
        setShowSavePropertiesOnly(filters.showSavedPropertiesOnly);
      }

      //UPDATES FROM NATALIE
      if (filters.customer !== undefined) {
        setSelectCustomerOptions(new Set(filters.customer));
      }
      //END

      if (filters.buildingStatus !== undefined) {
        setSelectBuildingStatusOptions(new Set(filters.buildingStatus));
      }

      if (filters.buildingSizeMin !== undefined) {
        setBuildingSizeMin(filters.buildingSizeMin);
      }

      if (filters.buildingClass !== undefined) {
        setSelectBuildingClassOptions(new Set(filters.buildingClass));
      }

      if (filters.parkingMin !== undefined) {
        setParkingMin(filters.parkingMin);
      }

      if (filters.amenitiesSearchText !== undefined) {
        setAmenitiesSearchText(filters.amenitiesSearchText);
      }

      if (filters.closestBranch !== undefined) {
        setClosestBranch(filters.closestBranch);
      }

      if (filters.driveTimeMax !== undefined) {
        setDriveTimeMax(filters.driveTimeMax);
      }

      if (filters.selectedZips !== undefined) {
        setSelectZipOptions(new Set(filters.selectedZips));
      }

      if (filters.selectedCities !== undefined) {
        setSelectCityOptions(new Set(filters.selectedCities));
      }

      if (filters.selectedStates !== undefined) {
        setSelectStateOptions(new Set(filters.selectedStates));
      }

      if (filters.showCRMProspects !== undefined) {
        setShowCRMProspects(filters.showCRMProspects);
      }

      if (filters.crmStatus !== undefined) {
        setSelectedCRMStatusOptions(new Set(filters.crmStatus));
      }

      if (filters.crmCompetitor !== undefined) {
        setCRMCompetitor(filters.crmCompetitor);
      }

      if (filters.showContracts !== undefined) {
        setShowContracts(filters.showContracts);
      }

      if (filters.contractsBranch !== undefined) {
        setContractsBranch(filters.contractsBranch);
      }

      if (filters.contractsAccountManager !== undefined) {
        setContractsBranch(filters.contractsAccountManager);
      }

      if (filters.contractsCustomer !== undefined) {
        setContractsBranch(filters.contractsCustomer);
      }

      if (filters.contractsMarketSegment !== undefined) {
        setContractsBranch(filters.contractsMarketSegment);
      }

      //updates DALTON

      if (filters.MHI_number !== undefined) {
        setMHI(filters.MHI_number);
      }

      if (filters.showMHI !== undefined) {
        setshowMHI(filters.showMHI);
      }

      if (filters.showdrivetime !== undefined) {
        setshowdrivetime(filters.showdrivetime);
      }
      if (filters.showheatmap !== undefined) {
        setshowheatmap(filters.showheatmap);
      }
      if (filters.showHOA !== undefined) {
        setShowHOA(filters.showHOA)
      }

      // end updates DALTON



      const expression = buildMoreFilterExpressions(
        filters.showSavedPropertiesOnly,
        new Set(filters.customer), //UPDATES FROM NATALIE
        new Set(filters.buildingStatus),
        filters.buildingSizeMin,
        new Set(filters.buildingClass),
        filters.parkingMin,
        filters.amenitiesSearchText,
        filters.closestBranch,
        filters.driveTimeMax,
        new Set(filters.crmStatus),
        filters.crmCompetitor,
        filters.contractsBranch,
        filters.contractsAccountManager,
        filters.contractsCustomer,
        filters.contractsMarketSegment,
        filters.MHI_number, // updates DALTON
        filters.showMHI, // updates DALTON
        filters.showdrivetime, // updates DALTON
        filters.showheatmap, // updates DALTON
        new Set(filters.selectedZips),
        new Set(filters.selectedCities),
        new Set(filters.selectedStates),
        filters.showHOA
      );
      setMoreFilterExpressions(expression);
    }

    if (!sessionLoaded.current) {
      sessionLoaded.current = true;
      if (location.state && (location.state as any).search) {
        const session = (location.state as any).search as Session;
        buildAndSetFilterExpressions(session.data.filters);
      } else {
        const sessionJson = sessionStorage.getItem(props.filtersStorageKey);
        if (sessionJson) {
          const session = JSON.parse(sessionJson) as Session;
          buildAndSetFilterExpressions(session.data.filters);
        }
      }
    }
  }, [
    props.view,
    props.filtersStorageKey,
    location.state,
    buildPropertyTypeExpression,
    buildLotSizeExpression,
    buildContactExpression,
    buildMoreFilterExpressions
  ]);

  // updates from lydia
  useEffect(() => {
    return () => {
      const filters: SessionFilters = {
        propertyTypes: Array.from(selectedPropertyTypes),
        secondarySelectedPropertyTypes,
        lotSizeMin,
        contactTypes: Array.from(selectedContactTypes),
        contactSearchTerms: contactSearchTerms,
        showSavedPropertiesOnly,
        customer: Array.from(selectedCustomerOptions), //UPDATES FROM NATALIE
        buildingStatus: Array.from(selectedBuildingStatusOptions),
        buildingSizeMin,
        buildingClass: Array.from(selectedBuildingClassOptions),
        parkingMin,
        amenitiesSearchText,
        closestBranch,
        driveTimeMax,
        showCRMProspects,
        crmStatus: Array.from(selectedCRMStatusOptions),
        crmCompetitor,
        showContracts,
        contractsBranch,
        contractsAccountManager,
        contractsCustomer,
        contractsMarketSegment,
        MHI_number, // updates DALTON
        showMHI, // updates DALTON
        showdrivetime, // updates DALTON
        showheatmap, // updates DALTON
        selectedZips: Array.from(selectedZipOptions),
        selectedCities: Array.from(selectedCityOptions),
        selectedStates: Array.from(selectedStateOptions),
        showHOA,
      }
      const session: Session = {
        id: -1,
        data: {
          filters,
          mapExtent: props.view.current?.extent,
          filterByMapExtent: props.filterByMapExtent,
          boundary: props.boundary
        }
      }
      sessionStorage.setItem(props.filtersStorageKey, JSON.stringify(session));
    }
  }, [selectedPropertyTypes, secondarySelectedPropertyTypes, lotSizeMin, selectedContactTypes, contactSearchTerms, showSavedPropertiesOnly, selectedCustomerOptions, selectedBuildingStatusOptions, buildingSizeMin, selectedBuildingClassOptions, parkingMin, amenitiesSearchText, closestBranch, driveTimeMax, selectedZipOptions, selectedCityOptions, selectedStateOptions, showCRMProspects, selectedCRMStatusOptions, crmCompetitor, showContracts, contractsBranch, contractsAccountManager, contractsCustomer, contractsMarketSegment, MHI_number, showMHI, showdrivetime, showheatmap, props.view, props.filterByMapExtent, props.boundary, props.filtersStorageKey, showHOA])
  // end updates from lydia

  // Updates DALTON

  useEffect(() => {

    const ParcelLayer = props.view.current.map.findLayerById('ParelLayer');
    if (ParcelLayer) {
      ParcelTileLayer.current = ParcelLayer as __esri.TileLayer;
      ParcelTileLayer.current.visible = showParcels;
    }

  }, [props.view, showParcels])

  useEffect(() => {

    //const HOALayer = props.view.current.map.findLayerById('hoaLayer');
    const HOALayer = props.view.current.map.findLayerById('hoaLayer') as FeatureLayer;
    const HOAMarketLayer = props.view.current.map.findLayerById('hoaMarketLayer');
    const PropertyLayer = props.view.current.map.findLayerById('Property');
    const MarketPropertyLayer = props.view.current.map.findLayerById('MarketSummary');
    if (HOALayer) {
      const query = new Query();
      query.outFields = ['*']; // Select all fields
      query.returnGeometry = false;
      query.where = props.crmRecordBaseFilterExpression
      query.maxRecordCountFactor = 5
      hoaLayer.current = HOALayer as __esri.FeatureLayer;
      hoaLayer.current.visible = showHOA;
      HOALayer.queryFeatures(query).then((results) => {

        if (results.features.length) {
          setHOAcount(results.features.length.toLocaleString());
        }

      }

      )
    }
    if (HOAMarketLayer) {
      hoaMarketLayer.current = HOAMarketLayer as __esri.FeatureLayer;
      hoaMarketLayer.current.visible = showHOA;
    }
    if (PropertyLayer) {
      propertyLayer.current = PropertyLayer as __esri.FeatureLayer;
      propertyLayer.current.visible = !showHOA;
    }
    if (MarketPropertyLayer) {
      marketSummaryLayer.current = MarketPropertyLayer as __esri.FeatureLayer;
      marketSummaryLayer.current.visible = !showHOA;
    }

  }, [props.view, showHOA])

  const toggleParcelVisibility = () => {
    setShowParcels(!showParcels);
  }

  const toggleHOAVisibility = () => {
    setShowHOA(!showHOA);
  }

  useEffect(() => {

    const heatlayer = props.view.current.map.findLayerById('ContractHeatMap');
    if (heatlayer) {
      contractHeatMapLayer.current = heatlayer as __esri.FeatureLayer;
      contractHeatMapLayer.current.visible = showheatmap;
    }

  }, [props.view, showheatmap])

  const toggleheatVisibility = () => {
    setshowheatmap(!showheatmap);
  }

  useEffect(() => {

    const drivelayer = props.view.current.map.findLayerById('drivetimelayer');
    if (drivelayer) {
      driveTimeLayer.current = drivelayer as __esri.FeatureLayer;
      driveTimeLayer.current.visible = showdrivetime;
    }

  }, [props.view, showdrivetime])

  const toggledriveVisibility = () => {
    setshowdrivetime(!showdrivetime);
    ;
  }

  useEffect(() => {

    /* const MHIlayer = props.view.current.map.findLayerById('MapImageLayer');
       if (MHIlayer) {
         MHI.current = MHIlayer as __esri.MapImageLayer;
         MHI.current.visible = showMHI;
       }*/

    const MHItime = props.view.current.map.findLayerById('MapImageLayer') as __esri.MapImageLayer;
    const zips = MHItime.findSublayerById(9);
    zips.visible = showMHI

  }, [props.view, showMHI])

  const toggleMHIVisibility = () => {
    setshowMHI(!showMHI);
    ;
  }

  const onStateOptionChange = (evt: any) => {
    setSelectStateOptions(evt)
  }

  const onZipOptionChange = (evt: any) => {
    setSelectZipOptions(evt)
  }

  const onCityOptionChange = (evt: any) => {
    setSelectCityOptions(evt)
  }

  const handleExport = () => {

    const hoaLayer = props.view.current.map.findLayerById('hoaLayer') as FeatureLayer;

    let visible: boolean | null = null

    // Check layer visibility when view extent changes

    if (props.view.current.zoom >= 8) {
      try {
        console.log(visible)
        // Get the current map extent
        const extent = props.view.current.extent;

        // Create a query based on the current map extent
        const query = new Query();
        query.geometry = extent;
        query.where = props.crmRecordBaseFilterExpression;
        query.spatialRelationship = 'intersects';
        query.outFields = ['*']; // Select all fields
        query.returnGeometry = false;

        // Execute the query
        hoaLayer.queryFeatures(query).then((results) => {

          if (results.features.length) {

          // Extract attributes from the results
          const featuresData = results.features.map((feature) => feature.attributes);

          // Convert features data to CSV format
          const csvContent = convertToCSV(featuresData);

          // Set your desired file name
          const filename = `HOA_export_SRSales.csv`;

          // Create a Blob and download the CSV file with the specified filename
          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          }
          else {
            alert("No HOA locations in view")
          }
        });

      } catch (error) {
        console.error('Error exporting features:', error);
      }
    }
    else {
      console.log(visible)
      alert('Please zoom in to view of HOA layer to export data')
    }
  };

  // Helper function to convert JSON data to CSV format
  const convertToCSV = (data: any[]) => {
    if (!data.length) return '';

    const keys = Object.keys(data[0]);
    const csvRows = [
      keys.join(','), // Header row
      ...data.map((row: { [x: string]: any; }) =>
        keys.map((key) => JSON.stringify(row[key], replacer)).join(',')
      ),
    ];

    return csvRows.join('\n');
  };

  // Helper function to handle CSV value escaping
  const replacer = (key: any, value: null | undefined) => {
    if (value === null || value === undefined) {
      return '';
    }
    return value;
  };


  const hasAnyMoreFilters = () => {
    return Object.keys(moreFilterExpressions.property).find(key => (moreFilterExpressions.property as any)[key])
      || Object.keys(moreFilterExpressions.crmProspects).find(key => (moreFilterExpressions.crmProspects as any)[key])
      || Object.keys(moreFilterExpressions.activeContracts).find(key => (moreFilterExpressions.activeContracts as any)[key])
      || ((MHI_number !== '' && MHI_number > 0)/* || showParcels*/) // updates DALTON
      || (!showdrivetime && !showheatmap && showMHI) // updates DALTON
      || showHOA
  };

  const hasMoreFilters = hasAnyMoreFilters();

  /*useEffect(() => {

    const contentElement = document.querySelector('.cds--content') as HTMLElement;

    console.log(contentElement)

    contentElement.style.marginTop = '3rem'

  },[showHOA, showMHI, showCRMProspects, showContracts])*/ 

  return (
    <span className="prospecting-filters">
      {/* Property Type */}
      <>
        <FilterDropdown
          buttonLabel="Type"
          // buttonLabel={selectedPropertyTypes.size > 0 ? `Type (${selectedPropertyTypes.size})` : "Type"}
          title="Type"
          buttonClass={propertyTypeExpression === '' ? 'filter-button' : 'filter-button has-active-filter'}
          onReset={resetPropertyTypeFilters}
          onApply={applyPropertyTypeFilters}>
          <div className="type-filters">
            {propertyTypeOptions.map((option, index) => (
              <div>
                {/*updates from lydia*/}
                <Checkbox
                  key={index}
                  labelText={option}
                  value={option}
                  id={`type-filter-option-${index}`}
                  checked={selectedPropertyTypes.has(option)}
                  onChange={onPropertyTypeCheckboxChange}
                />
                {selectedPropertyTypes.has(option) &&
                  <div className="search-text-container">
                    {secondaryOptions[option].map((secondary, secondaryIndex) => (
                      <>
                        <Checkbox
                          key={secondaryIndex}
                          labelText={secondary}
                          value={secondary}
                          id={`secondary-type-filter-option-${index}-${secondaryIndex}`}
                          checked={hasSecondary(secondarySelectedPropertyTypes[option], secondary)}
                          onChange={onSecondaryPropertyTypeCheckboxChange(option)} />
                      </>
                    ))}
                  </div>
                }
                {/*end updates from lydia*/}
              </div>
            ))}
          </div>
        </FilterDropdown>

        {/* Lot Size */}
        <FilterDropdown
          buttonLabel="Lot size"
          title="Lot size"
          buttonClass={lotSizeExpression === '' ? 'filter-button' : 'filter-button has-active-filter'}
          onReset={resetLotSizeFilter}
          onApply={applyLotSizeFilter}>
          <div className="lot-size-filters">
            <NumberInput id="lot-size-input"
              value={lotSizeMin}
              label='Lot size greater than'
              helperText='acres'
              iconDescription='Increment/Decrement'
              hideSteppers={true}
              onChange={onLotSizeChange}
              allowEmpty={true}
              min={0}
              step={1}
            />
          </div>
        </FilterDropdown>

        {/* Contact Info */}
        <FilterDropdown
          buttonLabel="Contact"
          title="Contact info"
          buttonClass={contactExpression === '' ? 'filter-button' : 'filter-button has-active-filter'}
          onReset={resetContactFilters}
          onApply={applyContactFilters}>
          <div className="contact-filters">
            {contactTypes.current.map(contactType => (
              <div key={contactType.value}>
                <div className="small-title">{contactType.label}</div>
                <Checkbox
                  labelText="Has contact info"
                  value={contactType.value}
                  id={`contact-filter-${contactType.value}`}
                  checked={selectedContactTypes.has(contactType.value)}
                  onChange={onContactCheckboxChange}
                />
                {selectedContactTypes.has(contactType.value) &&
                  <div className="search-text-container">
                    <TextInput
                      id={`contact-info-${contactType.value}-input`}
                      type='text'
                      hideLabel={true}
                      labelText={`Search ${contactType.label}`}
                      value={contactSearchTerms[contactType.value]}
                      onChange={onContactSearchTermChange(contactType.value)}
                      placeholder='Search...' />
                  </div>
                }
              </div>
            ))}
          </div>
        </FilterDropdown>

        {/* More Filters */}
        <FilterDropdown
          buttonLabel="More filters"
          title="More filters"
          buttonClass={hasMoreFilters ? 'filter-button has-active-filter' : 'filter-button'}
          onReset={resetMoreFilters}
          onApply={applyMoreFilters}>
          <div className="more-filters">
            <Accordion>
              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      Saved properties
                    </span>
                    {showSavedPropertiesOnly &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        On
                      </Tag>
                    }
                  </span>}
              >
                <div className="filter-section">
                  <Toggle labelText="Show saved properties only"
                    labelA="Off"
                    labelB="On"
                    toggled={showSavedPropertiesOnly}
                    onToggle={toggleShowSavedPropertiesOnly}
                    id="saved-properties-toggle"
                  />
                </div>
              </AccordionItem>

              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      Status
                    </span>
                    {selectedBuildingStatusOptions.size > 0 &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {selectedBuildingStatusOptions.size}
                      </Tag>
                    }
                  </span>}
              >
                <div className="filter-section">
                  <div className="filter-section-header">
                    <span className="small-title">
                      Status
                    </span>
                    <Button
                      onClick={clearBuildingStatusFilters}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  {buildingStatusOptions.map((option, index) => (
                    <Checkbox
                      key={index}
                      labelText={option}
                      value={option}
                      id={`building-status-option-${index}`}
                      checked={selectedBuildingStatusOptions.has(option)}
                      onChange={onBuildingStatusCheckboxChange} />
                  ))}
                </div>
              </AccordionItem>

              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      Building size (sq ft)
                    </span>
                    {buildingSizeMin !== '' && buildingSizeMin > 0 &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {1}
                      </Tag>
                    }
                  </span>}
              >
                <div className='filter-section'>
                  <div className="filter-section-header">
                    <span className="small-title">
                      Building size greater than
                    </span>
                    <Button
                      onClick={clearBuildingSizeFilter}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <NumberInput id="building-size-input"
                    value={buildingSizeMin}
                    label='Building size greater than'
                    helperText='sq ft'
                    iconDescription='Increment/Decrement'
                    hideSteppers={true}
                    onChange={(evt: any) => { setBuildingSizeMin(evt.target.value) }}
                    allowEmpty={true}
                    min={0}
                    step={1}
                    hideLabel={true}
                  />
                </div>
              </AccordionItem>

              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      Class
                    </span>
                    {selectedBuildingClassOptions.size > 0 &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {selectedBuildingClassOptions.size}
                      </Tag>
                    }
                  </span>}
              >
                <div className="filter-section">
                  <div className="filter-section-header">
                    <span className="small-title">
                      Class
                    </span>
                    <Button
                      onClick={clearBuildingClassFilters}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  {buildingClassOptions.map((option, index) => (
                    <Checkbox
                      key={index}
                      labelText={option}
                      value={option}
                      id={`building-class-option-${index}`}
                      checked={selectedBuildingClassOptions.has(option)}
                      onChange={onBuildingClassCheckboxChange} />
                  ))}
                </div>
              </AccordionItem>

              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      Parking
                    </span>
                    {parkingMin !== '' && parkingMin > 0 &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {1}
                      </Tag>
                    }
                  </span>}
              >
                <div className='filter-section'>
                  <div className="filter-section-header">
                    <span className="small-title">
                      Parking spaces greater than
                    </span>
                    <Button
                      onClick={clearParkingFilter}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <NumberInput id="parking-input"
                    value={parkingMin}
                    label='Parking spaces greater than'
                    iconDescription='Increment/Decrement'
                    hideSteppers={true}
                    onChange={(evt: any) => { setParkingMin(evt.target.value) }}
                    allowEmpty={true}
                    min={0}
                    step={1}
                    hideLabel={true}
                  />
                </div>
              </AccordionItem>

              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      Amenities
                    </span>
                    {amenitiesSearchText !== '' &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {1}
                      </Tag>
                    }
                  </span>}
              >
                <div className="filter-section">
                  <div className="filter-section-header">
                    <span className="small-title">
                      Amenities
                    </span>
                    <Button
                      onClick={clearAmenitiesFilter}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <TextInput
                    id={`amenities-input`}
                    type='text'
                    hideLabel={true}
                    labelText='Search Amentities'
                    value={amenitiesSearchText}
                    onChange={(evt: any) => { setAmenitiesSearchText(evt.target.value) }}
                    placeholder='Search...' />
                </div>
              </AccordionItem>

              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      Drive time
                    </span>
                    {(closestBranch !== '' || driveTimeMax > 0) &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {(closestBranch !== '' ? 1 : 0) + (driveTimeMax > 0 ? 1 : 0)}
                      </Tag>
                    }
                  </span>}
              >
                <div className="filter-section">
                  <div className="filter-section-header">
                    <span className="small-title">
                      Closest branch
                    </span>
                    <Button
                      onClick={clearClosestBranchFilter}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <Select
                    id="closest-branch-select"
                    value={closestBranch}
                    onChange={(evt: any) => { setClosestBranch(evt.target.value) }}
                    labelText="Closest branch"
                    hideLabel={true}>
                    <SelectItem
                      key={0}
                      value={''}
                      text={`Any`}
                    />
                    {branchOptions.map((branch, index) => {
                      return (
                        <SelectItem
                          key={index}
                          value={branch}
                          text={branch}
                        />
                      );
                    })}
                  </Select>
                  <br />
                  {/* <MultiSelect
                  label="Time from branch"
                  id="drive-time-multiselect"
                  titleText="Time from branch"
                  items={driveTimeOptions}
                  itemToString={(item: any) => `${item} minutes`}
                  selectedItems={Array.from(selectedDriveTimeOptions)}
                  onChange={onDriveTimeMultiSelectChange}
                  direction='top'
                /> */}

                  <div className="filter-section-header">
                    <span className="small-title">
                      Time from branch
                    </span>
                    <Button
                      onClick={clearDriveTimeMaxFilter}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <Select
                    id="drive-time-select"
                    value={driveTimeMax}
                    onChange={(evt: any) => { setDriveTimeMax(evt.target.value) }}
                    labelText="Time from branch"
                    hideLabel={true}>
                    <SelectItem
                      key={0}
                      value={0}
                      text={`Any`}
                    />
                    {driveTimeOptions.map((driveTime) => {
                      return (
                        <SelectItem
                          key={driveTime}
                          value={driveTime}
                          text={`Less than ${driveTime} minutes`}
                        />
                      );
                    })}
                  </Select>
                </div>
              </AccordionItem>

              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      Location
                    </span>
                    {(Array.from(selectedStateOptions).length > 0 || Array.from(selectedCityOptions).length > 0 || Array.from(selectedZipOptions).length > 0) &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {Array.from(selectedStateOptions).length + Array.from(selectedCityOptions).length + Array.from(selectedZipOptions).length}
                      </Tag>
                    }
                  </span>}
              >
                <div className="filter-section">

                  <div className="filter-section-header">
                    <span className="small-title">

                    </span>
                    <Button
                      onClick={clearLocationFilter}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  {Array.from(selectedStateOptions).length === 0 && !locationLoaded &&
                    <InlineLoading
                      status="active"
                      iconDescription="Active Loading Indicator"
                      description="Loading filter options..."
                    />
                  }
                  {(locationLoaded || Array.from(selectedStateOptions).length > 0) &&
                    <MultiSelect
                      id="StateSelect"
                      label="Select States"
                      titleText="State"
                      //helperText="Select a branch/es to filter contracts by"
                      items={stateOptions}
                      itemToString={(item: any) => item}
                      selectedItems={Array.from(selectedStateOptions)}
                      selectionFeedback="top-after-reopen"
                      onChange={(evt: any) => { onStateOptionChange(evt.selectedItems) }}
                    >
                    </MultiSelect>
                  }
                  <br />
                  {Array.from(selectedStateOptions).length > 0 && !locationLoaded && Array.from(selectedCityOptions).length === 0 &&
                    <InlineLoading
                      status="active"
                      iconDescription="Active Loading Indicator"
                      description="Loading filter options..."
                    />
                  }
                  {(locationLoaded || Array.from(selectedCityOptions).length > 0) && Array.from(selectedStateOptions).length > 0 &&
                    <Tooltip title="Clear selected cities to see full list of zip codes again. Or vice versa with zip codes.">
                      <MultiSelect
                        id='CitySelect'
                        label="Select Cities"
                        titleText="City"
                        //helperText="Select a branch/es to filter contracts by"
                        items={cityOptions}
                        itemToString={(item: any) => item}
                        selectedItems={Array.from(selectedCityOptions)}
                        selectionFeedback="top-after-reopen"
                        onChange={(evt: any) => { onCityOptionChange(evt.selectedItems) }}
                      >
                      </MultiSelect>
                    </Tooltip>
                  }
                  {Array.from(selectedStateOptions).length == 0 && locationLoaded &&
                    <MultiSelect
                      id='CitySelect'
                      label="Select Cities"
                      disabled={true}
                      titleText="City"
                      //helperText="Select a branch/es to filter contracts by"
                      items={cityOptions}
                      itemToString={(item: any) => item}
                      selectedItems={Array.from(selectedCityOptions)}
                      selectionFeedback="top-after-reopen"
                      onChange={(evt: any) => { onCityOptionChange(evt.selectedItems) }}
                    >
                    </MultiSelect>
                  }
                  <br />
                  {Array.from(selectedStateOptions).length > 0 && !locationLoaded && Array.from(selectedZipOptions).length === 0 &&
                    <InlineLoading
                      status="active"
                      iconDescription="Active Loading Indicator"
                      description="Loading filter options..."
                    />
                  }
                  {(locationLoaded || Array.from(selectedZipOptions).length > 0) && Array.from(selectedStateOptions).length > 0 &&
                    <Tooltip title="Clear selected zip codes to see full list of cities again. Or vice versa with cities.">
                      <MultiSelect
                        id='ZipSelect'
                        label="Select Zip Codes"
                        titleText="Zip Code"
                        //helperText="Select a branch/es to filter contracts by"
                        items={zipOptions}
                        itemToString={(item: any) => item}
                        selectedItems={Array.from(selectedZipOptions)}
                        selectionFeedback="top-after-reopen"
                        onChange={(evt: any) => { onZipOptionChange(evt.selectedItems) }}
                      >
                      </MultiSelect>
                    </Tooltip>
                  }
                  {Array.from(selectedStateOptions).length == 0 && locationLoaded &&
                    <MultiSelect
                      id='ZipSelect'
                      label="Select Zip Codes"
                      disabled={true}
                      titleText="Zip Code"
                      //helperText="Select a branch/es to filter contracts by"
                      items={zipOptions}
                      itemToString={(item: any) => item}
                      selectedItems={Array.from(selectedZipOptions)}
                      selectionFeedback="top-after-reopen"
                      onChange={(evt: any) => { onZipOptionChange(evt.selectedItems) }}
                    >
                    </MultiSelect>
                  }
                  {/*<Select
                    id="crm-competitor-select"
                    value={crmCompetitor}
                    onChange={(evt: any) => { setCRMCompetitor(evt.target.value) }}
                    labelText="State"
                    hideLabel={false}>
                    <SelectItem
                      key={'-1'}
                      value={''}
                      text={`All States`}
                    />
                    {stateOptions.map((competitor) => {
                      return (
                        <SelectItem
                          key={competitor}
                          value={competitor}
                          text={competitor}
                        />
                      );
                    })}
                  </Select>*/}
                  <br />
                  <div className='filter-note'>
                    Filter commercial properties by location with City, State, and Zip Code selections.
                  </div>
                </div>
              </AccordionItem>

              {/*UPDATES FROM NATALIE*/}
              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      Customer
                    </span>
                    {selectedCustomerOptions.size > 0 &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {selectedCustomerOptions.size}
                      </Tag>
                    }
                  </span>}
              >
                <div className="filter-section">
                  <div className="filter-section-header">
                    <span className="small-title">
                      Customer (Aspire)
                    </span>
                    <Button
                      onClick={clearCustomerFilters}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <div className='filter-note'>
                    This filter will show properties with an active Aspire maintenance contract nearby (200 ft), or properties without an active contract nearby.
                  </div>
                  <br></br>
                  {savedSearchesLoaded && customerOptions.map((option, index) => (
                    <Checkbox
                      key={index}
                      labelText={option}
                      value={option}
                      id={`customer-option-${index}`}
                      checked={selectedCustomerOptions.has(option)}
                      //checked={setCustomerOption("YES")}
                      //onChange={Customereffect}
                      onChange={onCustomerCheckboxChange}
                    />
                  ))}
                  {!savedSearchesLoaded &&
                    <InlineLoading
                      status="active"
                      iconDescription="Active Loading Indicator"
                      description="Loading filter options..."
                    />
                  }
                </div>
              </AccordionItem>
              {/*END UPDATES FROM NATALIE*/}


              {/*UPDATES FROM DALTON*/}
              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      Parcels
                    </span>
                    {(showParcels || showMHI) && (
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {showParcels && showMHI ? 2 : 1}
                      </Tag>
                    )}
                  </span>}
              >
                <div className='filter-section'>
                  <span className='small-title'>
                    Property Boundary Toggle
                  </span>
                  <Toggle
                    labelText=''
                    id="ParcelData"
                    labelA="Show Property Boundaries"
                    labelB="Hide Property Boundaries"
                    className="ParcelData"
                    data-toggle="tooltip"
                    data-placement="right"
                    toggled={showParcels}
                    onToggle={() => { toggleParcelVisibility(); props.view.current.zoom = 19 }}
                  />
                  <br className="mobile-break"></br>
                  <span className='small-title'>
                    Zip Code Toggle
                  </span>
                  <Toggle
                    labelText=''
                    id="DriveView"
                    labelA="Show Zip Codes"
                    labelB="Hide Zip Codes"
                    className="ParcelData"
                    data-toggle="tooltip"
                    data-placement="right"
                    toggled={!showdrivetime && !showheatmap && showMHI}
                    onToggle={() => { toggledriveVisibility(); toggleheatVisibility(); toggleMHIVisibility(); props.view.current.zoom = 9 }}
                  />
                  <br className="mobile-break"></br>
                  <div className="filter-section-header">
                    <span className="small-title">
                      MHI greater than
                    </span>
                    <Button
                      onClick={clearMHI}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <NumberInput id="MHI-input"
                    value={MHI_number}
                    //ref={MHIinput}
                    label='MHI greater than'
                    helperText=''
                    iconDescription='Increment/Decrement'
                    hideSteppers={true}
                    onChange={(evt: any) => setMHI(evt.target.value)}
                    //onSubmit={(evt: any) => {evt.preventDefault(); MHIeffect()}}
                    allowEmpty={true}
                    min={0}
                    step={1}
                    hideLabel={true}
                  />

                  <br />
                  <div className='filter-note'>
                    This filter shows zip codes that have a Median Household Income greater than the specified value.
                  </div>
                </div>
              </AccordionItem>
              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      <img src={HOA} alt='' className="HOA-logo"></img>
                      HOA (Google)
                    </span>
                    {showHOA &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {1}
                      </Tag>
                    }
                  </span>}
              >
                <div className='filter-section'>
                  <div className="HOA-header">
                    <span className='small-title'>
                      HOA Toggle
                    </span>
                    <span className="HOA-export-span">
                      <IconButton
                        className="HOA-export-button"
                        onClick={(e: any) => handleExport()}
                        label="Export HOAs in view"
                        align="left"
                        kind="ghost"
                        disabled={!showHOA}
                      >
                        <Download size={16} className="download" />
                      </IconButton>
                    </span>
                  </div>
                  <Toggle
                    labelText=''
                    id="HOAtoggle"
                    labelA="Show HOA properties"
                    labelB="Hide HOA properties"
                    //className="ParcelData"
                    data-toggle="tooltip"
                    data-placement="right"
                    toggled={showHOA}
                    onToggle={() => { toggleHOAVisibility() }}
                  />
                  {showHOA &&
                  <div className="HOA-line">
                    <p>HOA Properties Available:</p>
                    <Tag
                      className="count-tag-hoa"
                      type="high-contrast"
                      size="sm">
                      {HOAcount}
                    </Tag>
                    </div>
                  }
                  <br className="mobile-break"></br>
                  <div className='filter-note'>
                    This filter hides all CoStar commercial properties and reveals HOA-related properties extracted from Google Maps.
                  </div>

                </div>
              </AccordionItem>

              {/*END UPDATES FROM DALTON*/}

              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      <Collaborate className="aspire-filter-icons" /> CRM prospects (Aspire) {/*UPDATES FROM NATALIE: Icon for Aspire filter*/}
                    </span>
                    {(selectedCRMStatusOptions.size > 0 || crmCompetitor !== '') &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {selectedCRMStatusOptions.size + (crmCompetitor !== '' ? 1 : 0)}
                      </Tag>
                    }
                  </span>}
              >
                <div className="filter-section">
                  <Toggle labelText="Hide from map"
                    labelA="Show CRM prospects"
                    labelB="Hide CRM prospects"
                    toggled={showCRMProspects}
                    onToggle={toggleCRMPospectsVisibility}
                    id="crm-prospects-toggle"
                  />
                  <br />
                  <div className="filter-section-header">
                    <span className="small-title">
                      Status
                    </span>
                    <Button
                      onClick={clearCRMStatusFilters}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  {crmStatusOptions.map((option, index) => (
                    <Checkbox
                      key={index}
                      labelText={option}
                      value={option}
                      id={`crm-propect-status-option-${index}`}
                      checked={selectedCRMStatusOptions.has(option)}
                      onChange={onCRMStatusCheckboxChange} />
                  ))}
                  <br />

                  <div className="filter-section-header">
                    <span className="small-title">
                      Competitor
                    </span>
                    <Button
                      onClick={clearCRMCompetitorFilter}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <Select
                    id="crm-competitor-select"
                    value={crmCompetitor}
                    onChange={(evt: any) => { setCRMCompetitor(evt.target.value) }}
                    labelText="Competitor"
                    hideLabel={true}>
                    <SelectItem
                      key={'-1'}
                      value={''}
                      text={`All competitors`}
                    />
                    {crmCompetitorOptions.map((competitor) => {
                      return (
                        <SelectItem
                          key={competitor}
                          value={competitor}
                          text={competitor}
                        />
                      );
                    })}
                  </Select>
                  <br />
                  <div className='filter-note'>
                    These fields will only affect the map display of Aspire properties, and will not change the generated properties list.
                  </div>
                </div>
              </AccordionItem>

              <AccordionItem
                onClick={applyOneAtATime} //update from lydia
                title={
                  <span className='accordion-title'>
                    <span>
                      <TrophyFilled className="aspire-filter-icons" /> Active contracts (Aspire) {/*UPDATES FROM NATALIE: Icon for Aspire filter*/}
                    </span>
                    {(contractsBranch !== '' || contractsAccountManager !== ''
                      || contractsCustomer !== '' || contractsMarketSegment !== '') &&
                      <Tag
                        className="count-tag"
                        type="high-contrast"
                        size="sm">
                        {(contractsBranch !== '' ? 1 : 0) + (contractsAccountManager !== '' ? 1 : 0)
                          + (contractsCustomer !== '' ? 1 : 0) + (contractsMarketSegment !== '' ? 1 : 0)
                        }
                      </Tag>
                    }
                  </span>}
              >
                <div className="filter-section">
                  <Toggle labelText="Hide from map"
                    labelA="Show active contracts"
                    labelB="Hide active contract"
                    toggled={showContracts}
                    onToggle={toggleContractsVisibility}
                    id="contracts-toggle"
                  />
                  <br />

                  <div className="filter-section-header">
                    <span className="small-title">
                      Branch
                    </span>
                    <Button
                      onClick={clearContractsBranchFilter}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <Select
                    id="contracts-branch-select"
                    value={contractsBranch}
                    onChange={(evt: any) => { setContractsBranch(evt.target.value) }}
                    labelText="Branch"
                    hideLabel={true}>
                    <SelectItem
                      key={'-1'}
                      value={''}
                      text={`All branches`}
                    />
                    {contractsBranchOptions.map((option) => {
                      return (
                        <SelectItem
                          key={option}
                          value={option}
                          text={option}
                        />
                      );
                    })}
                  </Select>
                  <br />

                  <div className="filter-section-header">
                    <span className="small-title">
                      Account Manager
                    </span>
                    <Button
                      onClick={clearContractsAccountManagerFilter}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <Select
                    id="contracts-account-manager-select"
                    value={contractsAccountManager}
                    onChange={(evt: any) => { setContractsAccountManager(evt.target.value) }}
                    labelText="Account Manager"
                    hideLabel={true}>
                    <SelectItem
                      key={'-1'}
                      value={''}
                      text={`All account managers`}
                    />
                    {contractsAccountManagerOptions.map((option) => {
                      return (
                        <SelectItem
                          key={option}
                          value={option}
                          text={option}
                        />
                      );
                    })}
                  </Select>
                  <br />

                  <div className="filter-section-header">
                    <span className="small-title">
                      Customer
                    </span>
                    <Button
                      onClick={clearContractsCustomerFilter}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <Select
                    id="contracts-customer-select"
                    value={contractsCustomer}
                    onChange={(evt: any) => { setContractsCustomer(evt.target.value) }}
                    labelText="Customer"
                    hideLabel={true}>
                    <SelectItem
                      key={'-1'}
                      value={''}
                      text={`All customers`}
                    />
                    {contractsCustomerOptions.map((option) => {
                      return (
                        <SelectItem
                          key={option}
                          value={option}
                          text={option}
                        />
                      );
                    })}
                  </Select>
                  <br />

                  <div className="filter-section-header">
                    <span className="small-title">
                      Market Segment
                    </span>
                    <Button
                      onClick={clearContractsMarketSegmentFilter}
                      className="reset-filter-button"
                      size="sm"
                      kind="ghost">
                      Clear filter
                    </Button>
                  </div>
                  <Select
                    id="contracts-market-segment-select"
                    value={contractsMarketSegment}
                    onChange={(evt: any) => { setContractsMarketSegment(evt.target.value) }}
                    labelText="Market Segment"
                    hideLabel={true}>
                    <SelectItem
                      key={'-1'}
                      value={''}
                      text={`All segments`}
                    />
                    {contractsMarketSegmentOptions.map((option) => {
                      return (
                        <SelectItem
                          key={option}
                          value={option}
                          text={option}
                        />
                      );
                    })}
                  </Select>

                  <br />
                  <div className='filter-note'>
                    These fields will only affect the map display of Aspire properties, and will not change the generated properties list.
                  </div>
                </div>
              </AccordionItem>
            </Accordion>
          </div>
        </FilterDropdown>
      </>


      <Checkbox
        labelText="Filter as map moves"
        id="filter-by-map-extent-checkbox"
        className="filter-as-map-moves" //UPDATES FROM NATALIE: Filter as map moves blue...session.ts, SavedSearchListItem.tsx
        checked={props.filterByMapExtent}
        onChange={(evt: any) => { props.setFilterByMapExtent(evt.target.checked) }}
      />

      {
        (propertyTypeExpression !== '' || lotSizeExpression !== '' || contactExpression !== '' || hasMoreFilters) &&
        <Button
          onClick={resetAll}
          className="filter-action-button"
          kind="ghost"
          size="sm">
          Reset&nbsp;
          <Reset size={14} />
        </Button>
      }
      {
        //UPDATES FROM NATALIE: added props.filterByMapExtent
        (propertyTypeExpression !== '' || lotSizeExpression !== '' || contactExpression !== '' || hasMoreFilters || props.boundary || props.filterByMapExtent) &&
        <Button
          onClick={openSaveSearchModal}
          className="filter-action-button"
          kind="ghost"
          size="sm">
          Save&nbsp;
          <Save size={14} />
        </Button>
      }
      <Modal
        open={showSaveSearchModal}
        modalHeading="Save current search"
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onRequestClose={closeSaveSearchModal}
        onRequestSubmit={saveSearch}
        size="sm"
        selectorPrimaryFocus="#save-search-name-input"
        primaryButtonDisabled={!saveSearchName}>
        <p className="mb-1">
          Store the current map state and active filters for later access from Start / My Saved Searches.
        </p>
        <TextInput
          id="save-search-name-input"
          type="text"
          labelText="Name this search"
          value={saveSearchName}
          onChange={(evt: any) => { setSaveSearchName(evt.target.value) }}
          onKeyDown={(evt: any) => { if (evt.key === 'Enter' && saveSearchName) { saveSearch() } }}
          helperText="Be succinct yet descriptive enough to help your later access."
        />
      </Modal>
      {showSaveSuccess &&
        <ActionableNotification
          className="notification"
          actionButtonLabel="Go to Saved Searches&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;→"
          kind="info"
          lowContrast={true}
          onClose={() => { setShowSaveSuccess(false) }}
          onActionButtonClick={() => { navigate("/start/saved-searches", { replace: false }) }}
          statusIconDescription="Success"
          title="Search saved successfully!"
          subtitle={`"${saveSearchName}" has been added to your saved searches.`}
        />
      }
      {showSaveError &&
        <ToastNotification
          className="notification"
          kind="error"
          lowContrast={true}
          onClose={() => { setShowSaveError(false) }}
          statusIconDescription="Error"
          title="Search save failed."
          subtitle={`"${saveSearchName}" failed to save. Please try again.`}
          role='alert'
        />}
    </span>
  )
}
